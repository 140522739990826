import React from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const FinListPage = observer(() => (
  <>
    <HeaderTop title="Lãi suất ví tiền" />
    <div className="home-page !bg-[#13171a] pt-[46px]" />
  </>
));

export default FinListPage;
