import React from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const WithdrawPage = observer(() => (
  <>
    <HeaderTop title="Rút tiền" />
    <div className="home-page !bg-[#13171a] pt-[46px]">
      <div className="w-[95%] text-[18px] text-[#ccc] bg-[#191c23] p-[10px] rounded-[10px] mt-[15px] mx-[auto]">
        <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
          <span className="w-[30%] font-[700]">Họ tên mở tài khoản</span>
          <input
            type="text"
            defaultValue="Test"
            className="custom-input w-[70%] text-[14px] text-[#bbb] font-[700]"
          />
        </div>
        <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
          <span className="w-[30%] font-[700]">Số điện thoại</span>
          <input
            type="text"
            defaultValue="Test"
            className="custom-input w-[70%] text-[14px] text-[#bbb] font-[700]"
          />
        </div>
        <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
          <span className="w-[30%] font-[700]">Tài khoản ngân hàng</span>
          <input
            type="text"
            defaultValue="Test"
            className="custom-input w-[70%] text-[14px] text-[#bbb] font-[700]"
          />
        </div>
        <div className="py-[10px] px-[16px] flex items-center">
          <span className="w-[30%] font-[700]">Tên ngân hàng</span>
          <input
            type="text"
            defaultValue="Test"
            className="custom-input w-[70%] text-[14px] text-[#bbb] font-[700]"
          />
        </div>
        <div className="py-[10px] px-[16px] flex items-center">
          <span className="w-[30%] font-[700]">Số tiền rút</span>
          <input
            type="text"
            placeholder="Vui lòng nhập số tiền rút"
            className="custom-input w-[70%] text-[14px] text-[#bbb] font-[700]"
          />
        </div>
      </div>
      <div className="w-[85%] text-[18px] text-white text-center bg-[#c94848] rounded-[20px] p-[10px] mx-[auto] my-[15px]">
        Xác nhận
      </div>
    </div>
  </>
));

export default WithdrawPage;
