import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import i18next from "i18next";
import { Portal } from "react-portal";
import Usdt from "./Usdt";
import Option from "./Option";

export default function ListCoin({ open, onClose }) {
  const [active, setActive] = useState("usdt");

  return (
    <Portal>
      <div className="left-draw" style={{ display: open ? "block" : "none" }}>
        <div className="mask" onClick={onClose} />
        <div className="content">
          <div className="title">{i18next.t("monetary_situation")}</div>
          <div className="tabs tab-head">
            <div
              className={classNames("tab", { active: active === "option" })}
              onClick={() => setActive("option")}
            >
              {i18next.t("options")}
            </div>
            <div
              className={classNames("tab", { active: active === "usdt" })}
              onClick={() => setActive("usdt")}
            >
              USDT
            </div>
          </div>
          {active === "usdt" && <Usdt onClose={onClose} />}
          {active === "option" && <Option onClose={onClose} />}
        </div>
      </div>
    </Portal>
  );
}
