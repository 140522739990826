import React from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const RechargePage = observer(() => (
  <>
    <HeaderTop title="Nạp tiền vào ví" />
    <div className="home-page !bg-[#13171a] pt-[46px]">
      <Link
        to="/member/deposit?symbol=usdt"
        className="w-full p-[12px] bg-[#191c23] flex items-center gap-[5px]"
      >
        <span className="flex-1 text-[16px] text-[#bbb]">Nạp tiền vào ví</span>
        <FontAwesomeIcon icon={faChevronRight} color="#969799" />
      </Link>
    </div>
  </>
));

export default RechargePage;
