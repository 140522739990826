import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import KycForm from "./KycForm";
import KycFormFirst from "./KycFormFirst";

export default function KYCFirst() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general, clear },
  } = useStores();

  return (
    <div className="container mt-5">
      <HeaderTop title={i18next.t("primary_verification")} />
      <div className="sbui-card my-5">
        <div className="sbui-card-content">
          <KycFormFirst />
        </div>
      </div>
    </div>
  );
}
