import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import NoData from "_common/component/NoData";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

const FundRecordPage = observer(() => {
  const [activeTab, setActiveTab] = useState(1);
  const {
    authStore: { user},
  } = useStores();

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  return (
    <>
      <HeaderTop title="Nhật ký tiền vốn" />
      <div className="home-page !bg-[#13171a] pt-[46px]">
        <div className="h-[140px] text-[14px] text-white font-700 bg-[#c94848] rounded-[10px] my-[10px] mx-[5px] flex flex-col justify-center items-center">
          <span>USDTSố dư</span>
          <span>{formatNumber(usdtBalance?.amount, "0,0.00")}</span>
          <span>≈</span>
          <span>{formatNumber(usdtBalance?.convert_amount, "0,0")} VND</span>
        </div>
        <div className="w-[90%] h-[80px] bg-[#191c23] mt-[10px] mx-[auto] flex">
          <div className="flex-1 flex justify-center items-center">
            <span
              className={`w-[90px] text-[18px] text-white text-center bg-black px-[5px] rounded-[20px] border-[1px] border-white ${
                activeTab === 1 && "!bg-[#c94848]"
              }`}
              onClick={() => setActiveTab(1)}
            >
              Nhận vào
            </span>
          </div>
          <div className="flex-1 flex justify-center items-center">
            <span
              className={`w-[90px] text-[18px] text-white text-center bg-black px-[5px] rounded-[20px] border-[1px] border-white ${
                activeTab === 2 && "!bg-[#c94848]"
              }`}
              onClick={() => setActiveTab(2)}
            >
              Chi ra
            </span>
          </div>
        </div>
        <div className="mt-[50px]">
          <NoData />
        </div>
      </div>
    </>
  );
});

export default FundRecordPage;
