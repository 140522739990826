import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";

import HeaderTop from "_common/component/Layout/Header/HeaderTop";

export default function IntroductionDetail() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams();
  const {
    authStore: { user, questions, image_introduction },
  } = useStores();

  const id = params?.id;

  const content = useMemo(
    () => questions?.find((obj) => obj.id === Number(id)),
    [questions, id]
  );

  return (
    <div className="container introduce-page  mt-10">
      <HeaderTop title={i18next.t(content?.title)} />
      <div className="content-questions">{i18next.t(content?.content)}</div>
    </div>
  );
}
