import React from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const ServicePage = observer(() => (
  <>
    <HeaderTop title="Hướng dẫn người mới" />
    <div className="home-page !bg-[#13171a] pt-[46px]" />
  </>
));

export default ServicePage;
