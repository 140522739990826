import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Portal } from "react-portal";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";
import { useChat } from "_common/component/ChatProvider";
import { ChatApi } from "pages/Chat/_api/chat";

function ModalEditChat({ onClose, data }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general },
  } = useStores();
  const { pushChat } = useChat();

  const [content, setContent] = useState("");

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) => ChatApi.update({ id: data?.id, params: variables }),
    {
      onSuccess: (res, variables) => {
        onClose();
        pushChat({
          type: "edit_chat",
          id: data?.id,
          message: variables?.message,
        });
      },
      onError: (error) => {
        const errorMessage = error?.message ?? i18next.t("please_try_again");
        NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    if (!data) return;
    setContent(data?.message);
  }, [data]);

  const handleChangeInput = (event) => {
    setContent(event?.target?.value);
  };

  const handleSubmit = () => {
    if (!content) return NotificationManager.error(i18next.t("please_enter_a_group_name"));

    onSubmit({ message: content });
    return null;
  };

  return (
    <Portal>
      <div className="modal">
        <div className="modal-mask" />
        <div className="modal-root">
          <div className="modal-title">
            <h4>{i18next.t("edit_chat_content")}</h4>
          </div>
          <div className="modal-content pb-24">
            <input
              type="text"
              className="form-control bg-light border-light"
              placeholder={i18next.t("enter_content")}
              value={content}
              onChange={handleChangeInput}
            />
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button type="button" className="btn-cancel" onClick={onClose}>
                {i18next.t("close")}
              </button>
              <button type="button" className="btn-contact-cskh" onClick={handleSubmit}>
                {i18next.t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalEditChat;
