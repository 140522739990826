import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faSun,
  faMoon,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";

export default function GameHeader({ game_id, data_price }) {
  const { dataGame } = useNotify();
  const navigate = useNavigate();

  return (
    <div className="feature-header">
      <div className="action-group">
        <div className="coin">
          <p>
            <span>
              <b className="text-uppercase">{game_id?.split("usdt")?.[0]}</b>
              /USDT
            </span>
            <span
              className={classNames("price", {
                "text-red": Number(data_price?.priceChangePercent) <= 0,
                "text-green": Number(data_price?.priceChangePercent) > 0,
              })}
            >
              {formatNumber(dataGame?.lastPrice, "0,0.[0000]")}
            </span>
          </p>
          <h5
            className={classNames({
              "text-red": Number(data_price?.priceChangePercent) <= 0,
              "text-green": Number(data_price?.priceChangePercent) > 0,
            })}
          >
            <span>{formatNumber(data_price?.priceChange, "0,0.[000]")}</span>
            <span className="ml-2">
              {formatNumber(data_price?.priceChangePercent, "0,0.[00]")}%
            </span>
          </h5>
        </div>
        <button
          type="button"
          className="btn btn-history"
          onClick={() => navigate("/board/feature/history")}
        >
          <span>{i18next.t("hold_position")}</span>
        </button>
      </div>
    </div>
  );
}
