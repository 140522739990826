import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Portal } from "react-portal";
import SelectItem from "./SelectItem";

function SelectBox({ options = [], defaultValue, onChange, className }) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValue || options[0]);

  const handleClick = useCallback(
    (event) => {
      const { target } = event;
      if (open && !ref.current?.contains(target)) {
        setOpen(false);
      }
    },
    [open]
  );

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const handleChange = (item) => {
    if (typeof onChange === "function") {
      onChange(item);
    }
    setSelected(item);
    setOpen(false);
  };

  return (
    <div className={classNames("sbui-select-container", className)}>
      <div className="sbui-listbox-container">
        <div className="relative">
          <button
            className={classNames("sbui-listbox sbui-listbox--large")}
            type="button"
            onClick={() => setOpen(!open)}
          >
            <span className="sbui-listbox-addonbefore">
              <span className="sbui-listbox-label">{selected?.label}</span>
            </span>
            <span className="sbui-listbox-chevron-container">
              <svg
                className="sbui-listbox-chevron"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </button>
          {open && (
            <ul className="sbui-listbox-option-container" ref={ref}>
              {options?.map((item, index) => (
                <SelectItem
                  key={`option-${index?.toString()}`}
                  onChange={() => handleChange(item)}
                  isSelect={item?.value === selected?.value}
                  isDisable={item?.disabled}
                >
                  {item?.label}
                </SelectItem>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default SelectBox;
