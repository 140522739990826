import classNames from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import i18next from "i18next";

import icon1 from "assets/img/icon/index.png";
import icon1_active from "assets/img/icon/index_select.png";
import icon2 from "assets/img/icon/vip.png";
import icon2_active from "assets/img/icon/vip_select.png";
import icon3 from "assets/img/icon/task.png";
import icon3_active from "assets/img/icon/task_select.png";
import icon4 from "assets/img/icon/user_input.png";
import icon4_active from "assets/img/icon/user_select.png";

export default function MobileFooter() {
  const location = useLocation();
  const pathName = location?.pathname;

  return (
    <div className="footer">
      <Link
        to="/"
        className={classNames("footer_item", {
          active: pathName === "/",
        })}
      >
        <img src={pathName === "/" ? icon1_active : icon1} alt="" />
        <h5>{i18next.t("page_home")}</h5>
      </Link>
      <Link
        to="/hall"
        className={classNames("footer_item", {
          active: pathName === "/hall",
        })}
      >
        <img src={pathName === "/hall" ? icon2_active : icon2} alt="" />
        <h5>Sảnh</h5>
      </Link>
      <Link
        to="/task"
        className={classNames("footer_item", {
          active: pathName === "/task",
        })}
      >
        <img src={pathName === "/task" ? icon3_active : icon3} alt="" />
        <h5>Đơn Hàng</h5>
      </Link>
      <Link
        to="/member"
        className={classNames("footer_item", {
          active: pathName === "/member",
        })}
      >
        <img src={pathName === "/member" ? icon4_active : icon4} alt="" />
        <h5>Của Tôi</h5>
      </Link>
    </div>
  );
}
