import React from "react";

import { initReactI18next, I18nextProvider } from "react-i18next";
import i18next from "i18next";

// @ts-ignore
import common_vi from "_common/i18n/vi/common.json";
// @ts-ignore
import common_cn from "_common/i18n/cn/common.json";
// @ts-ignore
import common_eng from "_common/i18n/eng/common.json";
// @ts-ignore
import code2 from "_common/i18n/code2/common.json";
// @ts-ignore
import code3 from "_common/i18n/code3/common.json";
// @ts-ignore
import code4 from "_common/i18n/code4/common.json";
// @ts-ignore
import code5 from "_common/i18n/code5/common.json";
// @ts-ignore
import code6 from "_common/i18n/code6/common.json";
// @ts-ignore
import code7 from "_common/i18n/code7/common.json";
// @ts-ignore
import code8 from "_common/i18n/code8/common.json";
// @ts-ignore
import code9 from "_common/i18n/code9/common.json";
// @ts-ignore
import code10 from "_common/i18n/code10/common.json";
// @ts-ignore
import code11 from "_common/i18n/code11/common.json";
// @ts-ignore
import code12 from "_common/i18n/code12/common.json";

import "react-notifications/lib/notifications.css";
import "swiper/swiper-bundle.css";
import "./assets/scss/style.scss";
import Root from "./Root";

const i18n = i18next.use(initReactI18next);

i18n.init({
  react: {
    wait: true,
  },
  interpolation: { escapeValue: false },
  lng: "vi",
  fallbackLng: "vi",
  resources: {
    vi: {
      messages: common_vi,
    },
    cn: {
      messages: common_cn,
    },
    en: {
      messages: common_eng,
    },
    code2: {
      messages: code2,
    },
    code3: {
      messages: code3,
    },
    code4: {
      messages: code4,
    },
    code5: {
      messages: code5,
    },
    code6: {
      messages: code6,
    },
    code7: {
      messages: code7,
    },
    code8: {
      messages: code8,
    },
    code9: {
      messages: code9,
    },
    code10: {
      messages: code10,
    },
    code11: {
      messages: code11,
    },
    code12: {
      messages: code12,
    },
  },
  keySeparator: ".",
  ns: ["messages"],
  defaultNS: "messages",
  fallbackNS: [],
});

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Root />
    </I18nextProvider>
  );
}

export default App;
