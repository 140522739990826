import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { formatNumber } from "_common/utils/formatValue";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";
import i18next from "i18next";

const Option = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, coin_options, games },
  } = useStores();

  const [gameList, setGameList] = useState(games);

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinListPrice = useMemo(
    () =>
      gameList.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [gameList, data]
  );

  return (
    <div className="coin-list">
      <div className="list">
        <div className="item header">
          <div className="col1">{i18next.t("first_name")}</div>
          <div className="col2">{i18next.t("latest_price")}</div>
          <div className="col3">{i18next.t("increase_decrease_amplitude")}</div>
        </div>
      </div>
    </div>
  );
});

export default Option;
