import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import SelectBox from "_common/component/SelectBox";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { useSearchParams } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import GameBoard from "./GameBoard";
import GameHeader from "./GameHeader";
import HistoryPrice from "./HistoryPrice";
import HistoryTrade from "./HistoryTrade";

const FeaturePage = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();

  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";

  const [game_id, setGameId] = useState(games[0]?.code);
  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  return (
    <>
      <HeaderTop title={i18next.t("contract")} />
      <div className="feature-page">
        <GameHeader data_price={priceBtc} game_id={game_id} />
        <div className="flex">
          <div className="flex-1">
            <GameBoard data_price={priceBtc} game_id={game_id} />
          </div>
          <div
            style={{
              width: "40%",
            }}
          >
            <HistoryPrice priceBtc={priceBtc} />
          </div>
        </div>
        <HistoryTrade />
      </div>
    </>
  );
});

export default FeaturePage;
