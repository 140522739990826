import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { FundApi } from "states/api/fund";
import logo from "assets/img/logo.png";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

export default function ListData({ data = [] }) {
  const navigate = useNavigate();

  return (
    <div className="sbui-loading">
      <HeaderTop title={i18next.t("main_management_products")} />
      <div className="list-history-data">
        {data?.map((item) => (
          <div key={item?.id} className="item">
            <div className="flex  justify-between gap-2">
              <div className="flex items-center gap-4">
                <img src={logo} alt="" />
                <span className="text-lg font-bold">{item?.name}</span>
              </div>
              <button
                type="button"
                className="sbui-btn  btn-fund-add"
                onClick={() => navigate(`/board/fund/detail/${item?.id}`)}
              >
                {i18next.t("buy_now")}
              </button>
            </div>
            <div className="flex  justify-between items-center gap-2 mt-5">
              <div className="flex flex-col items-center">
                <h4 className="text-sm text-center">
                  {i18next.t("limit_per_transaction")}
                </h4>
                <span className="text-sm   text-center text-blue-500">
                  {formatNumber(item?.min)} ~ {formatNumber(item?.max)}
                </span>
              </div>
              <div className="flex flex-col items-center">
                <h4 className="text-sm text-center">
                  {i18next.t("daily_earnings_rate")}
                </h4>
                <span className="text-sm   text-center text-blue-500">
                  {formatNumber(item?.min_profit, "0,0.[000]")}% ~{" "}
                  {formatNumber(item?.max_profit, "0,0.[000]")}%
                </span>
              </div>
              <div className="flex flex-col items-center">
                <h4 className="text-sm text-center">{i18next.t("game_no")}</h4>
                <span className="text-sm   text-center text-blue-500">
                  {formatNumber(item?.date)}(Ngày)
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
