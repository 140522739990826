import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function Dropdown({ children }) {
  const ref = useRef(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="dropdown" ref={ref}>
      <button
        className={classNames("btn btn-ghost-secondary btn-icon ", {
          show: active,
        })}
        type="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={() => setActive(!active)}
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </button>
      <div
        className={classNames("dropdown-menu dropdown-menu-end  ", {
          show: active,
        })}
        data-popper-placement="bottom-end"
      >
        {children}
      </div>
    </div>
  );
}

export default Dropdown;
