import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next, { use } from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import coin_address from "assets/img/coin_address.png";
import { handleCopyLink } from "_common/utils/helper";
import QRCode from "react-qr-code";
import UploadFile from "_common/component/UploadFile";
import { UserWalletApi } from "states/api/wallet";

const schema = yup
  .object({
    symbol: yup.string().required(i18next.t("verify_require")),
    address: yup.string().required(i18next.t("verify_require")),
  })
  .required();

export default function WalletAddress() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [back_images, setBackImage] = useState();

  const {
    authStore: { user, general, user_wallet, game_wallet, coins },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      UserWalletApi.create({
        params: {
          ...variables,
          image: back_images,
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        setBackImage();
        queryClient.invalidateQueries(["user_wallet", "list"]);
        navigate(-1);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("add_success")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    onSubmit({ ...values });
    return null;
  };

  const onReceiveBackImages = (images) => {
    setBackImage(images[0]?.url);
  };

  return (
    <div className="container  px-4 mx-auto mt-10">
      <HeaderTop title={i18next.t("add_wallet_address")} />
      <div id="withdraw">
        <div className="flex flex-col w-full gap-10  mt-6">
          <div className="flex flex-1">
            <div className="sbui-card w-full h-full">
              <form
                className="sbui-card-content"
                onSubmit={handleSubmit(onSave)}
              >
                <div className="flex flex-col w-full gap-4">
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="text-gray-600 text-sm">
                          {i18next.t("currency")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <select
                            className="sbui-select sbui-select--medium"
                            {...register("symbol")}
                          >
                            {coins.map((item) => (
                              <option key={item?.id} value={item?.symbol}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="text-gray-600 text-sm">
                          {i18next.t("coin_deposit_address")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            className="sbui-input sbui-input--medium"
                            placeholder={i18next.t("coin_deposit_address")}
                            {...register("address")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div>
                      <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                        <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                          <div className="text-gray-600 text-sm">
                            {i18next.t("wallet_qr_code")}
                          </div>
                        </div>
                        <div className="sbui-formlayout__content-container-horizontal">
                          <UploadFile
                            onReceiveImages={onReceiveBackImages}
                            image={back_images}
                            folder="payment"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="sbui-btn-container sbui-btn--w-full">
                    <button
                      type="submit"
                      className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                      disabled={!isValid}
                    >
                      <span>{i18next.t("send")}</span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
