import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const FundEndpoint = `${endpoint}/funds`;

export const FundApi = {
  getList: async ({ params }) =>
    await api
      .get(`${FundEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getHistory: async ({ params }) =>
    await api
      .get(`${FundEndpoint}/histories`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${FundEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${FundEndpoint}/create`, params)
      .then((result) => result.data),
  update: async ({ id, params }) =>
    await api
      .put(`${FundEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${FundEndpoint}/delete/${id}`)
      .then((result) => result.data),
};
