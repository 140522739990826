import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import api from "states/drivers";
import { endpoint } from "_common/constants/common";
import { Portal } from "react-portal";
import i18next from "i18next";

function ModalImage({ allow_submit, onReceiveImages, files, onClose, image, ...props }) {
  const [fileListState, setFileListState] = useState([]);
  const [image_src, setImageSrc] = useState();

  useEffect(() => {
    if (!files) return;
    const reader = new FileReader();
    reader.onload = function(event) {
      const src = event.target.result;
      setImageSrc(src);
    };

    reader.readAsDataURL(files);
  }, [files]);

  useEffect(() => {
    if (!image) return;
    setImageSrc(image);
  }, [image]);

  const handleUploadFailed = (res) => {
    console.log("upload failed", res);

    const { file } = res;
    const data = [...fileListState];
    const index = data.findIndex((i) => i.uid === file.uid);
    data.splice(index, 1);

    setFileListState(data);
  };

  const handleSuccess = ({ url, id, uid, group_code, file }) => {
    const newImage = {
      isNew: true,
      group_code,
      id,
      uid,
      name: get(file, "name"),
      status: "done",
      url,
    };
    const dataImages = [...fileListState, newImage];

    setFileListState(dataImages);
    if (typeof onReceiveImages === "function") {
      onReceiveImages(dataImages);
    }
  };

  const onFileUpload = async (file) => {
    try {
      // Create an object of formData
      const formData = new FormData();

      const uid = new Date().getTime();

      // Update the formData object
      formData.append("file", file, file?.name);
      formData.append("folder", props?.folder);
      formData.append("type", props?.type);
      formData.append("room_id", props?.room_id);
      formData.append("user_id", props?.user_id);
      formData.append("app_id", "2");
      formData.append("secure_code", "remix");
      formData.append("uid", uid);

      // Send formData object
      const result = await api.post(`${endpoint}/cskh/chat/send-image`, formData);
      console.log(result);
      if (get(result, "data.code") !== 200) throw new Error(get(result, "data.msg"));
      handleSuccess({
        file: get(file, "file"),
        uid,
        url: get(result, "data.data.thumb"),
        id: get(result, "data.data.id"),
        group_code: get(result, "data.data.group_code"),
      });
      onClose();
    } catch (err) {
      console.error(err);
      handleUploadFailed({ file });
    }
  };

  return (
    <Portal>
      <div className="modal modal-image">
        <div className="modal-mask" />
        <div className="modal-root">
          {allow_submit && (
            <div className="modal-title">
              <h4>{i18next.t("send_image")}</h4>
            </div>
          )}
          <div className="modal-content pb-24">
            <img src={image_src} alt="" />
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button type="button" className="btn-cancel" onClick={onClose}>
                {i18next.t("close")}
              </button>
              {allow_submit && (
                <button type="button" className="btn-contact-cskh" onClick={() => onFileUpload(files)}>
                  {i18next.t("send_picture")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalImage;
