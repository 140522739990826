import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Portal } from "react-portal";

const PopupUpgrade = observer(({ onOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <Portal>
      <div className="custom-modal">
        <div className="custom-mask" onClick={onClose} />
        <div className="custom-modal-body !w-[96%] !bg-white">
          <div className="custom-modal-header text-[16px] text-[#635327] text-center pt-[20px]">
            Thương gia nâng cấp
          </div>
          <div className="custom-modal-content w-[85%] flex flex-col">
            <div className="h-[300px] text-[#635327] px-[35px] mt-[80px]">
              Nguyên tắc cơ bản xác thực của thương gia
            </div>
          </div>
          <div className="custom-modal-footer border-t-[0.5px] border-[#13171a] flex">
            <span
              className="w-[50%] h-[48px] leading-[48px] text-[#635327] text-center border-r-[0.5px] border-[#13171a]"
              onClick={onClose}
            >
              Hủy bỏ
            </span>
            <span
              className="w-[50%] h-[48px] leading-[48px] text-[#ee0a24] text-center"
              onClick={onClose}
            >
              Tôi đồng ý
            </span>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default PopupUpgrade;
