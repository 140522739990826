import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { handleCopyLink } from "_common/utils/helper";
import QRCode from "react-qr-code";

export default function ShareLink() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general, clear },
  } = useStores();

  return (
    <div className="container  px-4 mx-auto mt-10">
      <HeaderTop title={i18next.t("i_want_to_share")} />
      <div className="sbui-card my-10">
        <div className="sbui-card-content">
          <div className="flex justify-center mt-6 ">
            {user.link_ref && (
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  width: "180px",
                }}
                viewBox="0 0 256 256"
                value={user.link_ref}
              />
            )}
          </div>

          <p className="text-sm text-center text-gray-500 mt-2">
            {i18next.t("share_link")}
          </p>
          <div className="diver" />
          <p className="text-sm text-center text-gray-500">{user.link_ref}</p>
          <div className="flex justify-center mt-6 ">
            <button
              type="submit"
              onClick={() => handleCopyLink(user.link_ref)}
              className="sbui-btn sbui-btn-primary sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
            >
              <span>{i18next.t("share_link")}</span>
            </button>
          </div>
          <p className="text-sm text-center text-gray-500 mt-2">
            {i18next.t("your_invitation_code")}
          </p>
          <div className="diver" />
          <p className="text-sm text-center text-gray-500">{user.ref_no}</p>
          <div className="flex justify-center mt-6 ">
            <button
              type="submit"
              onClick={() => handleCopyLink(user.ref_no)}
              className="sbui-btn sbui-btn-primary sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
            >
              <span>{i18next.t("copy_invitation_code")}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
