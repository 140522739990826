import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next, { use } from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const schema = yup
  .object({
    amount: yup.number().required(i18next.t("verify_require")),
    otp: yup.string(),
  })
  .required();

export default function WithdrawBank() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const symbol = params.get("symbol");

  const [amount, setAmount] = useState();

  const {
    authStore: { user, general, coins, bank_select },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  const mainBalance = useMemo(
    () => user?.tokens?.find((obj) => obj?.symbol === symbol),
    [user, symbol]
  );

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.withdraw({
        params: {
          ...variables,
          symbol,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;

    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_withdraw
        )}`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `Please enter a smaller amount ${formatNumber(general?.max_withdraw)}`
      );

    if (!bank_select?.id) {
      return NotificationManager.error(`Vui lòng chọn ngân hàng`);
    }
    onSubmit({ ...values, bank_id: bank_select?.id });
    return null;
  };

  return (
    <div className="container mx-auto mt-5">
      <HeaderTop title={i18next.t("withdraw")} />
      <div id="withdraw">
        <div className="flex flex-col w-full gap-10  mt-6">
          <div className="flex flex-1">
            <div className="sbui-card w-full h-full">
              <form
                className="sbui-card-content"
                onSubmit={handleSubmit(onSave)}
              >
                <div
                  className="bank-info"
                  onClick={() => navigate("/board/member/bank?type=select")}
                >
                  <div className="form__cell">
                    <div className="form__label">{i18next.t("first_name")}</div>
                    <div className="form__field">
                      <input
                        className="sbui-input"
                        placeholder={i18next.t("verify_require_name")}
                        readOnly
                        value={bank_select?.bank_owner}
                      />
                    </div>
                  </div>
                  <div className="form__cell">
                    <div className="form__label">
                      {i18next.t("bank_number")}
                    </div>
                    <div className="form__field">
                      <input
                        className="sbui-input"
                        placeholder={i18next.t("verify_require_name")}
                        readOnly
                        value={bank_select?.bank_number_display}
                      />
                    </div>
                  </div>
                  <div className="form__cell">
                    <div className="form__label">{i18next.t("bank_name")}</div>
                    <div className="form__field">
                      <input
                        className="sbui-input"
                        placeholder={i18next.t("verify_require_name")}
                        readOnly
                        value={bank_select?.bank_name}
                      />
                    </div>
                  </div>
                  <div className="form__cell">
                    <div className="form__label">
                      {i18next.t("bank_opening_address")}
                    </div>
                    <div className="form__field">
                      <input
                        className="sbui-input"
                        placeholder={i18next.t("verify_require_name")}
                        readOnly
                        value={bank_select?.bank_branch}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-4">
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="sbui-formlayout__label">
                          {i18next.t("withdrawal_amount")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            type="number"
                            inputMode="decimal"
                            min={0}
                            max={1000000000000}
                            className="sbui-input sbui-input--medium"
                            placeholder={i18next.t("coins_withdraw_msg")}
                            {...register("amount")}
                            onKeyUp={(e) => setAmount(e?.target?.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <span className="block text-sm text-gray-500 mt-2">
                      {i18next.t("availability")}:{" "}
                      {formatBalance(mainBalance?.amount)} {mainCoin?.name}
                    </span>
                  </div>
                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("fund_password")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          type="text"
                          className="sbui-input sbui-input--medium"
                          placeholder={i18next.t("fund_password_msg")}
                          {...register("wallet_password")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("transaction_fee")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          type="text"
                          className="sbui-input sbui-input--medium"
                          placeholder={`${i18next.t("transaction_fee")} 0%`}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <p className="text-center text-md text-blue-500">
                    {i18next.t("estimated_amount_received")}{" "}
                    {formatNumber(amount, "0,0.[00000]")}{" "}
                    <span className="text-uppercase">{symbol}</span>
                  </p>

                  <span className="sbui-btn-container sbui-btn--w-full">
                    <button
                      type="submit"
                      className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                      disabled={!isValid}
                    >
                      <span>{i18next.t("send")}</span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
