import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { PriceApi } from "states/api/price";
import { useQuery } from "@tanstack/react-query";
import { formatMoney, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import i18next from "i18next";

import icon1 from "assets/img/icon/nav01.png";
import icon2 from "assets/img/icon/nav02.png";
import icon3 from "assets/img/icon/nav03.png";
import icon4 from "assets/img/icon/nav04.png";
import icon5 from "assets/img/icon/nav05.png";
import icon6 from "assets/img/icon/nav06.png";
import icon7 from "assets/img/icon/nav07.png";
import icon8 from "assets/img/icon/nav08.png";
import iconStar1 from "assets/img/icon/star1.png";
import iconStar2 from "assets/img/icon/star2.png";
import PopupNotice from "./Popup/PopupNotice";

const HomeUnAuth = observer(() => {
  const [params] = useSearchParams();

  const {
    authStore: { logo, banners, top_coin, games },
  } = useStores();

  const [isShowPopupNotice, setIsShowPopupNotice] = useState(true);

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinTopPrices = useMemo(
    () =>
      top_coin.map((item) => {
        const price = data?.find((obj) => obj?.symbol === item?.code);

        return { ...item, price };
      }),
    [top_coin, data]
  );

  const coinListPrice = useMemo(
    () =>
      games.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [games, data]
  );

  return (
    <>
      <div className="bg_header !px-[15px] !py-[10px] !justify-start">
        <img src={logo} alt="" className="w-[100px]" />
      </div>
      <div className="home-page !bg-[#13171a] pt-[46px]">
        <div className="relative">
          <div className="h-[140px] bg-[#c94848] bg-no-repeat bg-center bg-cover">
            {/* <p className="text-[20px] text-white p-[30px]">Hoan nghênh</p> */}
          </div>
          <div className="w-[93%] h-[180px] max-w-[450px] bg-[#191c23] rounded-[10px] px-[5px] mt-[-45px] my-[20px] mx-[auto] flex flex-wrap">
            {/* <Link
              to="/member/fund-record"
              className="flex flex-col justify-center items-center basis-1/3"
            >
              <img src={icon1} alt="" className="w-[48px] h-[48px]" />
              <span className="text-[11px] text-[#ccc]">
                Nhật ký vay tiền vốn
              </span>
            </Link> */}
            <Link
              to="/service"
              className="flex flex-col justify-center items-center basis-1/3"
            >
              <img src={icon2} alt="" className="w-[48px] h-[48px]" />
              <span className="text-[11px] text-[#ccc]">Hướng Dẫn</span>
            </Link>
            <Link
              to="/member/invite"
              className="flex flex-col justify-center items-center basis-1/3"
            >
              <img src={icon3} alt="" className="w-[48px] h-[48px]" />
              <span className="text-[11px] text-[#ccc]">Giới thiệu</span>
            </Link>
            <Link
              to="/cskh"
              className="flex flex-col justify-center items-center basis-1/3"
            >
              <img src={icon4} alt="" className="w-[48px] h-[48px]" />
              <span className="text-[11px] text-[#ccc]">CSKH</span>
            </Link>
            <Link
              to="/member/wallet"
              className="flex flex-col justify-center items-center basis-1/3"
            >
              <img src={icon5} alt="" className="w-[48px] h-[48px]" />
              <span className="text-[11px] text-[#ccc]">Ví</span>
            </Link>
            {/* <Link
              to="/member/my-team"
              className="flex flex-col justify-center items-center basis-1/3"
            >
              <img src={icon6} alt="" className="w-[48px] h-[48px]" />
              <span className="text-[11px] text-[#ccc]">Danh sách nhóm</span>
            </Link> */}
            <Link
              to="/member/bind-account"
              className="flex flex-col justify-center items-center basis-1/3"
            >
              <img src={icon7} alt="" className="w-[48px] h-[48px]" />
              <span className="text-[11px] text-[#ccc]">
                Liên Kết Ngân Hàng
              </span>
            </Link>
            <Link
              to="/member/fund-record"
              className="flex flex-col justify-center items-center basis-1/3"
            >
              <img src={icon8} alt="" className="w-[48px] h-[48px]" />
              <span className="text-[11px] text-[#ccc]">
                Chi Tiết Tài Khoản
              </span>
            </Link>
          </div>
        </div>
        <div className="top-coin">
          {coinTopPrices.map((item) => (
            <Link key={item?.code} to="/" className="item">
              <h5>
                <b>{item?.name?.split("/")?.[0]}</b>/
                <span>{item?.name?.split("/")?.[1]}</span>
              </h5>
              <h4 className="price">
                {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
              </h4>
              <p
                className={classNames({
                  "text-green": item?.price?.priceChangePercent > 0,
                  "text-red": item?.price?.priceChangePercent <= 0,
                })}
              >
                {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
              </p>
            </Link>
          ))}
        </div>
        <div className="notice">
          <div className="left">
            <FontAwesomeIcon icon={faVolumeHigh} />
            <span>{i18next.t("10_langs_msg")}</span>
          </div>
        </div>
        <Swiper
          spaceBetween={30}
          centeredSlides
          pagination
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="swiper2 !m-[5px]"
        >
          {banners.map((item) => (
            <SwiperSlide key={item?.id}>
              {item.type === "image" && (
                <a href="/">
                  <img alt="" src={item?.image_url} />
                </a>
              )}
              {item.type === "video" && (
                <iframe
                  width="430"
                  height="200"
                  src={item?.src}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  title="video"
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="coin-list">
          {coinListPrice?.map((item) => (
            <div
              key={item?.id}
              className="bg-[#191c23] rounded-[10px] p-[5px] mx-[10px] mt-[5px] flex items-center"
            >
              <div className="w-[8%]">
                <img src={iconStar1} alt="" className="w-[16px] h-[19px]" />
              </div>
              <div className="w-[10%]">
                <img
                  src={item?.image_url}
                  alt=""
                  className="w-[24px] h-[24px]"
                />
              </div>
              <div className="w-[20%]">
                <span className="text-[16px] text-white">
                  {item?.name?.split("/")?.[0]}
                </span>
              </div>
              <div className="w-[40%] flex flex-col">
                <span className="text-[16px] text-white">
                  $ {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
                </span>
                <span className="text-[11px] text-white">
                  Vol: {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}k
                </span>
              </div>
              <div className="w-[22%] text-right pr-[10px]">
                <span className="text-[12px] text-white">
                  {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* {isShowPopupNotice && (
        <PopupNotice
          onOpen={isShowPopupNotice}
          onClose={() => setIsShowPopupNotice(false)}
        />
      )} */}
    </>
  );
});

export default HomeUnAuth;
