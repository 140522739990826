import React from "react";
import imgMain from "assets/img/maintenance.jpg";

export default function Maintenance() {
  return (
    <div className="maintenance-page">
      <img src={imgMain} alt="" />
    </div>
  );
}
