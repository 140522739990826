import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faSun,
  faMoon,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "_common/utils/formatValue";

export default function GameHeader({ data_price, game_id }) {
  return (
    <div className="feature-header">
      <div className="action-group">
        <div className="coin">
          <p>
            <span>
              <b className="text-uppercase">{game_id?.split("usdt")?.[0]}</b>
              /USDT
            </span>
            <span
              className={classNames("ml-2", {
                "text-red": Number(data_price?.priceChangePercent) <= 0,
                "text-green": Number(data_price?.priceChangePercent) > 0,
              })}
            >
              {formatNumber(data_price?.priceChangePercent, "0,0.[00]")}%
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
