import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BankApi } from "states/api/bank";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GameApi } from "states/api/game";
import { useNotify } from "_common/component/NotifyProvider";
import { PriceApi } from "states/api/price";

const schema = yup
  .object({
    amount: yup.string().required(i18next.t("verify_require")),
    price: yup.string().required(i18next.t("verify_require")),
  })
  .required();

const SellPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, common, my_bank, usdt_wallet },
  } = useStores();
  const { pushNotify } = useNotify();

  const {
    register,
    trigger,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries(["get_common"]);
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const usdtBalance = useMemo(
    () => common?.currency_list?.find((obj) => obj.symbol === "usdt"),
    [common]
  );

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.betGame({
        params: {
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
        reset();
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (!values?.amount)
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));

    onSubmit({
      game_id: "usdtvnd",
      type: "p2p",
      bet_game: "sell",
      price: usdtBalance?.price,
      ...values,
    });
    return null;
  };

  return (
    <>
      <HeaderTop title="Tôi muốn bán" />
      <form
        className="home-page !bg-[#13171a] pt-[46px]"
        onSubmit={handleSubmit(onSave)}
      >
        <div className="w-[95%] text-[14px] text-[#ccc] bg-[#191c23] p-[10px] rounded-[10px] mt-[15px] mx-[auto]">
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%]">Số dư(USDT)</span>
            <span className="w-[75%] text-[14px] text-[#bbb]">
              {formatNumber(usdt_wallet?.amount, "0,0.[00]")}
            </span>
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%]">Họ tên</span>
            <span className="w-[75%] text-[14px] text-[#bbb]">
              {my_bank?.bank_owner}
            </span>
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%]">Tài khoản ngân hàng</span>
            <span className="w-[75%] text-[14px] text-[#bbb]">
              {my_bank?.bank_number}
            </span>
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%]">Giá tiền trước đó(USDT)</span>
            <span className="w-[75%] text-[14px] text-[#bbb]">
              {formatNumber(usdtBalance?.price, "0,0.[00]")}
            </span>
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%]">Giá tiền bán ra ưu đãi nhất</span>
            <span className="w-[75%] text-[14px] text-[#bbb]">
              {formatNumber(usdtBalance?.price, "0,0.[00]")}
            </span>
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%]">Giá tiền bán ra</span>
            <input
              type="number"
              inputMode="decimal"
              placeholder="Vui lòng điền giá bán ra"
              className="custom-input !w-[75%] !text-[14px] text-[#bbb] !pl-[0]"
              step={0.000001}
              {...register("price")}
            />
            <span
              onClick={() => {
                setValue("price", Number(usdtBalance?.price));
              }}
              className="w-[20%] text-[16px] text-white text-center bg-[#c94848] py-[5px] rounded-[5px]"
            >
              Giá tiền ưu đãi nhất
            </span>
          </div>
          <div className="py-[10px] px-[16px] flex items-center">
            <span className="w-[25%]">Số lượng bán ra</span>
            <input
              type="number"
              inputMode="decimal"
              placeholder="Vui lòng điền số lượng bán ra"
              className="custom-input !w-[75%] !text-[14px] text-[#bbb] !pl-[0]"
              step={0.000001}
              {...register("amount")}
            />
            <span
              onClick={() => {
                setValue("amount", Number(usdt_wallet?.amount));
              }}
              className="w-[20%] text-[16px] text-white text-center bg-[#c94848] py-[5px] rounded-[5px]"
            >
              Toàn bộ
            </span>
          </div>
        </div>
        <button
          type="submit"
          className="button w-[85%] text-[18px] text-white text-center !bg-[#c94848] rounded-[25px] p-[10px] mx-[auto] my-[15px]"
        >
          Xác nhận bán ra
        </button>
      </form>
    </>
  );
});

export default SellPage;
