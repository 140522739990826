import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faIdCard } from "@fortawesome/free-solid-svg-icons";

import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { BankApi } from "states/api/bank";

export default function AddBankCard() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { setBankSelect },
  } = useStores();

  const [params] = useSearchParams();

  const type = params?.get("type");

  const { data, refetch } = useQuery(
    ["bank", "list"],
    () =>
      BankApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container mt-10">
      <HeaderTop title={i18next.t("manage_payment_methods")} />
      <div className="list-bank">
        <div
          className="item flex justify-between item-center w-full h-full"
          onClick={() => navigate("/board/member/bank/submit?bank_type=visa")}
        >
          <div className="flex item-center gap-2 ">
            <FontAwesomeIcon className="card-icon" icon={faIdCard} />
            <p className="text-sm">{i18next.t("add_bank_card")} (Quốc Tế)</p>
          </div>
          <FontAwesomeIcon className="text-blue-500" icon={faPlus} />
        </div>
        <div
          className="item flex justify-between  item-center w-full h-full"
          onClick={() => navigate("/board/member/bank/submit?bank_type=local")}
        >
          <div className="flex item-center gap-2 ">
            <FontAwesomeIcon className="card-icon" icon={faIdCard} />
            <p className="text-sm">{i18next.t("add_bank_card")}</p>
          </div>
          <FontAwesomeIcon className="text-blue-500" icon={faPlus} />
        </div>
      </div>
      <div className="card-note">{i18next.t("bank_help_msg")}</div>

      {data?.data?.length > 0 && (
        <div className="list-bank-info px-2 ">
          {data?.data?.map((item) => (
            <div
              key={item?.id}
              className="item card-note mt-2"
              onClick={() => {
                if (type === "select") {
                  setBankSelect(item);
                  navigate(-1);
                }
              }}
            >
              <div className=" flex justify-between  item-center w-full mb-1">
                <span className="text-gray-500">{i18next.t("first_name")}</span>
                <span className="text-gray-600">{item?.bank_owner}|</span>
              </div>
              <div className=" flex justify-between  item-center w-full mb-1">
                <span className="text-gray-500">
                  {i18next.t("bank_number")}
                </span>
                <span className="text-gray-600">
                  {item?.bank_number_display}|
                </span>
              </div>
              <div className=" flex justify-between  item-center w-full  mb-1">
                <span className="text-gray-500">{i18next.t("bank_name")}</span>
                <span className="text-gray-600">{item?.bank_name}|</span>
              </div>
              <div className=" flex justify-between  item-center w-full  mb-1">
                <span className="text-gray-500">
                  {i18next.t("bank_opening_address")}
                </span>
                <span className="text-gray-600">{item?.bank_branch}|</span>
              </div>
              <div className=" flex justify-between  item-center w-full  mb-1">
                <span className="text-gray-500">{i18next.t("status")}</span>
                <span className="text-gray-600">{i18next.t("success")}|</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
