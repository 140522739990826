import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const UserWalletEndpoint = `${endpoint}/user-wallet`;

export const UserWalletApi = {
  getList: async ({ params }) =>
    await api
      .get(`${UserWalletEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ params }) =>
    await api
      .get(`${UserWalletEndpoint}/get-bank`, params)
      .then((result) => result.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${UserWalletEndpoint}/create`, params)
      .then((result) => result.data),
  update: async ({ id, params }) =>
    await api
      .put(`${UserWalletEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${UserWalletEndpoint}/delete/${id}`)
      .then((result) => result.data),
};
