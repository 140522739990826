import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faTimes } from "@fortawesome/free-solid-svg-icons";

import HeaderTop from "_common/component/Layout/Header/HeaderTop";

export default function Introduction() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, questions, image_introduction },
  } = useStores();

  return (
    <div className="container introduce-page  mt-10">
      <HeaderTop title={i18next.t("support_center")} />
      <div className="list-questions">
        {questions?.map((item, index) => (
          <div
            className="item"
            key={`item-${index.toString()}`}
            onClick={() =>
              navigate(`/board/member/introduction/detail/${item?.id}`)
            }
          >
            <FontAwesomeIcon icon={faMoneyBill} />
            <span>{i18next.t(item?.title)}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
