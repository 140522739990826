import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import usdt from "assets/icons/usdt.svg";
import eth from "assets/icons/eth.svg";
import btc from "assets/icons/btc.svg";
import icon20 from "assets/img/icon/icon20.png";

export default function Recharge() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { partners },
  } = useStores();

  return (
    <div className="container page-deposit  px-4 mx-auto mt-10">
      <HeaderTop title={i18next.t("deposit")} />
      <div id="deposit" className=" mt-6">
        <div className="card">
          <p className="mb-4 text-gray-600">{i18next.t("recharge_msg")}</p>
          <p className="text-gray-600">{i18next.t("recharge_msg_2")}</p>
        </div>

        <div className="flex flex-col w-full gap-10 mt-2 ">
          <div className="flex flex-1">
            <div className="sbui-card w-full h-full">
              <div className="list-coin-deposit">
                <p className="mt-2 px-2 text-sm text-gray-700">
                  {i18next.t("select_recharge_channel")}
                </p>
                <Link
                  to="/board/deposit?symbol=usdt&network=erc20"
                  className="item"
                >
                  <div className="left">
                    <img src={usdt} alt="" />
                    <span>USDT(ERC20)</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link
                  to="/board/deposit?symbol=usdt&network=trc20"
                  className="item"
                >
                  <div className="left">
                    <img src={usdt} alt="" />
                    <span>USDT(TRC20)</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>

                <Link to="/board/deposit?symbol=btc" className="item">
                  <div className="left">
                    <img src={btc} alt="" />
                    <span>BTC</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link to="/board/deposit?symbol=eth" className="item">
                  <div className="left">
                    <img src={eth} alt="" />
                    <span>ETH</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
                <Link to="/board/deposit?symbol=eth" className="item">
                  <div className="left">
                    <img src={icon20} alt="" />
                    <span>{i18next.t("transfer")}</span>
                  </div>
                  <div className="right">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="list-partner">
          {partners?.map((item) => (
            <a href={item?.link} key={item.id} className="item">
              <img src={item?.image_url} alt="" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
