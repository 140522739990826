import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";

export default function HistoryPrice({ priceBtc, game_id }) {
  const {
    authStore: { trading_view },
  } = useStores();
  const { dataGame } = useNotify();

  function genRand(min, max) {
    return Math.random() * (max - min) + min;
  }

  const listBuy = useMemo(() => {
    const result = [];
    const beginPrice = Number(dataGame?.lastPrice);
    const alpha = beginPrice * 0.002;

    for (let index = 0; index < 10; index += 1) {
      result.push({
        price: beginPrice + index * alpha,
        volume: genRand(0, 1),
      });
    }

    return result;
  }, [dataGame]);

  const listSell = useMemo(() => {
    const result = [];
    const beginPrice = Number(dataGame?.lastPrice);
    const alpha = beginPrice * 0.002;

    for (let index = 0; index < 10; index += 1) {
      result.push({
        price: beginPrice - index * alpha,
        volume: genRand(0, 1),
      });
    }

    return result;
  }, [dataGame]);

  return (
    <div className="price-history">
      <div className="item">
        <div>
          <p>{i18next.t("price_2")}</p>
          <p>(USDT)</p>
        </div>
        <div>
          <p>{i18next.t("amount_2")}</p>
          <p>(BTC)</p>
        </div>
      </div>
      {listBuy.reverse().map((item, index) => (
        <div className="item" key={`buy-${index.toString()}`}>
          <div>
            <p className="text-green">{formatNumber(item.price, "0,0.[00]")}</p>
          </div>
          <div>
            <p>{formatNumber(item.volume, "0,0.[000000]")}</p>
          </div>
        </div>
      ))}
      {listSell.map((item, index) => (
        <div className="item" key={`buy-${index.toString()}`}>
          <div>
            <p className="text-red">{formatNumber(item.price, "0,0.[00]")}</p>
          </div>
          <div>
            <p>{formatNumber(item.volume, "0,0.[000000]")}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
