import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

const WalletPage = observer(() => {
  const {
    authStore: { user, game_wallet, general, clear },
  } = useStores();

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  const vndBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "vnd"),
    [user]
  );

  return (
    <>
      <HeaderTop title="Tài sản của tôi" />
      <div className="home-page !bg-[#13171a] pt-[46px]">
        <div className="h-[140px] text-white bg-[#c94848] rounded-[10px] my-[10px] mx-[5px] flex flex-col justify-center items-center">
          <span className="text-[14px]">Số dư tài khoản:</span>
          <span className="text-[14px]">
            {formatNumber(usdtBalance?.amount, "0,0.00")} (USDT)
          </span>
        </div>
        <div className="flex justify-center">
          <Link
            to="/member/recharge"
            className="w-[85%] text-[18px] text-white text-center bg-[#c94848] rounded-[20px] p-[10px] mx-[auto] my-[15px]"
          >
            Nạp tiền
          </Link>
        </div>
        <div className="p-[20px] mt-[50px]">
          <div className="text-[14px] text-white py-[5px] border-b-[1px] border-white flex items-center">
            <span className="w-[80%]">Số tiền đã nạp</span>
            <span className="w-[20%] text-right">
              {formatNumber(user?.bill_deposit, "0,0.000")} USDT
            </span>
          </div>
          <div className="text-[14px] text-white py-[5px] border-b-[1px] border-white flex items-center">
            <span className="w-[80%]">Số tiền đã bán ra</span>
            <span className="w-[20%] text-right">
              {formatNumber(user?.sell_total, "0,0.000")} USDT
            </span>
          </div>
          <div className="text-[14px] text-white py-[5px] border-b-[1px] border-white flex items-center">
            <span className="w-[80%]">Lợi nhuận đã nhận được</span>
            <span className="w-[20%] text-right">
              {formatNumber(user?.profit_total, "0,0.000")} USDT
            </span>
          </div>
        </div>
      </div>
    </>
  );
});

export default WalletPage;
