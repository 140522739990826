import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faTimes } from "@fortawesome/free-solid-svg-icons";

import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import classNames from "classnames";

export default function Language() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { setLanguage, languages, language },
  } = useStores();

  return (
    <div className="container language-page  mt-10">
      <HeaderTop title={i18next.t("language")} />
      <div className="list-lang">
        {languages?.map((item, index) => (
          <div
            className={classNames("item", {
              active: language === item?.code,
            })}
            key={`item-${index.toString()}`}
            onClick={() => setLanguage(item?.code)}
          >
            <span>{item?.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
