import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";

export default function ActiveCode() {
  const navigate = useNavigate();
  return (
    <form id="auth-sign-up">
      <div className="sbui-space-col sbui-space-y-6">
        <div className="sbui-space-col sbui-space-y-3">
          <p>{i18next.t("active_account_now")}</p>
        </div>
        <div
          className="sbui-space-col sbui-space-y-2"
          style={{ textAlign: "center" }}
        >
          <span
            onClick={() => navigate("/auth/login")}
            className=" sbui-typography-link inline-block btn-register px-4 py-2 mx-auto border rounded-lg dark:border-slate-700 border-slate-400"
          >
            {i18next.t("note_20")}
          </span>
        </div>
      </div>
    </form>
  );
}
