import React, { useState } from "react";
import { useStores } from "_common/hooks";
import { observer } from "mobx-react-lite";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";

import icon1 from "assets/img/icon/info_0001.png";
import icon2 from "assets/img/icon/info_0002.png";
import icon3 from "assets/img/icon/info_0005.png";
import PopupAvatar from "../Popup/PopupAvatar";
import PopupPassword from "../Popup/PopupPassword";

const UserInfoPage = observer(() => {
  const {
    authStore: { user },
  } = useStores();
  const queryClient = useQueryClient();

  const [isShowPopupAvatar, setIsShowPopupAvatar] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState();
  const [isShowPopupPwd, setIsShowPopupPwd] = useState(false);

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = () => {
    if (isLoading) return;

    mutate({
      avatar: avatarUrl,
    });
  };

  return (
    <>
      <HeaderTop title="Thông tin cá nhân" />
      <div className="home-page !bg-[#13171a] pt-[46px]">
        <div
          className="text-[14px] text-[#e7e7e7] p-[12px] bg-[#191c23] border-b-[1px] border-[#d9d9d9] mt-[20px] flex items-center"
          onClick={() => setIsShowPopupAvatar(true)}
        >
          <img src={icon1} alt="" className="w-[24px] h-[24px]" />
          <span className="ml-[10px] flex-1">Ảnh đại diện</span>
          <div className="flex-1 flex justify-end">
            <div className="w-[75px] h-[75px] bg-[#eff0f2] p-[6px] rounded-[50%]">
              <img
                src={avatarUrl ?? user?.avatar_url}
                alt=""
                className="w-full h-full rounded-[50%]"
              />
            </div>
          </div>
        </div>
        <div className="text-[14px] text-[#e7e7e7] p-[12px] bg-[#191c23] border-b-[1px] border-[#d9d9d9] flex items-center">
          <img src={icon2} alt="" className="w-[24px] h-[24px]" />
          <span className="ml-[10px] flex-1">Nick name</span>
          <div className="flex-1 flex justify-end">
            <span className="text-[15px]">{user?.nickname}</span>
          </div>
        </div>
        <div className="text-[14px] text-[#e7e7e7] p-[12px] bg-[#191c23] border-b-[1px] border-[#d9d9d9] flex items-center">
          <img src={icon2} alt="" className="w-[24px] h-[24px]" />
          <span className="ml-[10px] flex-1">Tài khoản</span>
          <div className="flex-1 flex justify-end">
            <span className="text-[15px]">{user?.username}</span>
          </div>
        </div>
        <div className="text-[14px] text-[#e7e7e7] p-[12px] bg-[#191c23] border-b-[1px] border-[#d9d9d9] flex items-center">
          <img src={icon2} alt="" className="w-[24px] h-[24px]" />
          <span className="ml-[10px] flex-1">Email</span>
          <div className="flex-1 flex justify-end">
            <span className="text-[15px]">{user?.email}</span>
          </div>
        </div>
        <div className="text-[14px] text-[#e7e7e7] p-[12px] bg-[#191c23] border-b-[1px] border-[#d9d9d9] flex items-center">
          <img src={icon2} alt="" className="w-[24px] h-[24px]" />
          <span className="ml-[10px] flex-1">Mã giới thiệu</span>
          <div className="flex-1 flex justify-end">
            <span className="text-[15px]">{user?.ref_no}</span>
          </div>
        </div>
        <div
          className="text-[14px] text-[#e7e7e7] p-[12px] bg-[#191c23] border-b-[1px] border-[#d9d9d9] flex items-center"
          onClick={() => setIsShowPopupPwd(true)}
        >
          <img src={icon3} alt="" className="w-[24px] h-[24px]" />
          <span className="ml-[10px] flex-1">Sửa đổi mật khẩu</span>
          <div className="flex-1 flex justify-end items-center">
            <span className="text-[15px]">Ấn để cài đặt</span>
            <FontAwesomeIcon
              icon={faChevronRight}
              color="#969799"
              className="ml-[10px]"
            />
          </div>
        </div>
        <div
          className="w-[85%] text-[18px] text-white text-center bg-[#c94848] rounded-[20px] p-[10px] mx-[auto] my-[15px]"
          onClick={onSave}
        >
          Lưu
        </div>
      </div>
      {isShowPopupAvatar && (
        <PopupAvatar
          onOpen={isShowPopupAvatar}
          onClose={() => setIsShowPopupAvatar(false)}
          setAvatarUrl={setAvatarUrl}
        />
      )}
      {isShowPopupPwd && (
        <PopupPassword
          onOpen={isShowPopupPwd}
          onClose={() => setIsShowPopupPwd(false)}
        />
      )}
    </>
  );
});

export default UserInfoPage;
