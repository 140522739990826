import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const PaymentEndpoint = `${endpoint}/payment`;

export const PaymentApi = {
  getList: async ({ params }) =>
    await api
      .get(`${PaymentEndpoint}/histories`, params)
      .then((result) => result.data.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${PaymentEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  withdraw: async ({ params }) =>
    await api
      .post(`${PaymentEndpoint}/withdraw`, params)
      .then((result) => result.data),
  deposit: async ({ params }) =>
    await api
      .post(`${PaymentEndpoint}/deposit`, params)
      .then((result) => result.data),
  exchange: async ({ params }) =>
    await api
      .post(`${PaymentEndpoint}/exchange`, params)
      .then((result) => result.data),
  transfer: async ({ params }) =>
    await api
      .post(`${PaymentEndpoint}/transfer`, params)
      .then((result) => result.data),
};
