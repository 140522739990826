import React from "react";
import icon_noMessage from "assets/img/icon/no_data.7d5de337.png";

export default function NoData({ description = "No data to display" }) {
  return (
    <div className="noMSG_out nodata">
      <div className="noMSG">
        <img alt="kubet" src={icon_noMessage} className="opacity-30" />
        <div className="text-[14px] text-[#969799]">{description}</div>
      </div>
    </div>
  );
}
