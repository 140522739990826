import React, { useState } from "react";
import { useStores } from "_common/hooks";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import ChatProvider from "_common/component/ChatProvider";

const SupportLayout = observer(() => {
  const { authStore } = useStores();

  if (!authStore.logo) return <PageLoading />;

  return (
    <ChatProvider user={authStore?.user}>
      <Outlet />
    </ChatProvider>
  );
});

export default SupportLayout;
