import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const GroupUserEndpoint = `${endpoint}/cskh/group-user`;

export const GroupUserApi = {
  getList: async ({ params }) =>
    await api
      .get(`${GroupUserEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ params }) =>
    await api
      .get(`${GroupUserEndpoint}/detail`, params)
      .then((result) => result.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${GroupUserEndpoint}/create`, params)
      .then((result) => result.data),
  update: async ({ id, params }) =>
    await api
      .put(`${GroupUserEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${GroupUserEndpoint}/delete/${id}`)
      .then((result) => result.data),
};
