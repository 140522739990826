import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const GroupEndpoint = `${endpoint}/cskh/group`;

export const GroupApi = {
  getList: async ({ params }) =>
    await api
      .get(`${GroupEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${GroupEndpoint}/detail/${id}`)
      .then((result) => result.data),
  getListMember: async ({ params }) =>
    await api
      .get(`${GroupEndpoint}/list-member`, params)
      .then((result) => result.data?.data),
  deleteMember: async ({ params }) =>
    await api
      .post(`${GroupEndpoint}/delete-member`, params)
      .then((result) => result.data),
  blockMember: async ({ params }) =>
    await api
      .post(`${GroupEndpoint}/block-member`, params)
      .then((result) => result.data),
  addMember: async ({ params }) =>
    await api
      .post(`${GroupEndpoint}/add-member`, params)
      .then((result) => result.data),
  create: async ({ params }) =>
    await api
      .post(`${GroupEndpoint}/create`, params)
      .then((result) => result.data),
  update: async ({ id, params }) =>
    await api
      .put(`${GroupEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${GroupEndpoint}/delete/${id}`)
      .then((result) => result.data),
};
