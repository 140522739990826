import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import Loading from "_common/component/Loading";
import i18next from "i18next";

import iconUser from "assets/img/icon/user_input.png";
import iconPwd from "assets/img/icon/pwd_input.png";
import iconSMS from "assets/img/icon/smscode_input.png";
import iconInvite from "assets/img/icon/invite_input.png";
import InputPassword from "_common/component/InputPassword";

import ActiveCode from "./ActiveCode";

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require_username")),
    password: yup.string().required(i18next.t("verify_require_password")),
    confirmed: yup
      .string()
      .required(i18next.t("verify_require_confirm_password"))
      .oneOf([yup.ref("password")], i18next.t("verify_unmatch_password")),
  })
  .required();

export default function ForgotPasswordForm({ type }) {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const ref_no = params?.get("ref_no");

  const [isSuccess, setIsSuccess] = useState(false);

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        // authStore.updateToken(res?.data);
        // authStore.updateUser(res?.data);
        // setTimeout(() => {
        //   navigate("/");
        // }, 200);
        setIsSuccess(true);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  useEffect(() => {
    if (!ref_no) return;
    setValue("ref_no", ref_no);
  }, [ref_no]);

  if (isSuccess) return <ActiveCode />;

  return (
    <form id="auth-sign-up" onSubmit={handleSubmit(handleRegister)}>
      {isLoading && <Loading />}
      <div className="sbui-space-col sbui-space-y-6">
        <div className="custom-input">
          <img src={iconUser} alt="" className="!w-[25px] !h-[25px]" />
          <input
            type="text"
            placeholder="Vui lòng nhập email"
            {...register("username")}
          />
        </div>
        <div className="custom-input !pr-[0]">
          <img src={iconSMS} alt="" className="!w-[25px] !h-[25px]" />
          <InputPassword
            type="text"
            placeholder="Vui lòng nhập mã xác nhận"
            autoComplete="current-password"
            {...register("password")}
          />
          <img
            src="https://www.veindirps.com/captcha/?rand=0.29685425867633985"
            alt=""
          />
        </div>
        <div className="custom-input">
          <img src={iconPwd} alt="" className="!w-[25px] !h-[25px]" />
          <InputPassword
            type="password"
            placeholder="Vui lòng nhập mật khẩu"
            autoComplete="current-password"
            {...register("password")}
          />
        </div>
        <button
          type="submit"
          className="custom-btn"
          disabled={!isValid || isLoading}
        >
          Cài đặt mật khẩu
        </button>
      </div>
    </form>
  );
}
