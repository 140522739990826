import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import KycForm from "./KycForm";

export default function KYC() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general, clear },
  } = useStores();

  return (
    <div className="container mt-5">
      <HeaderTop title={i18next.t("premium_verification")} />
      <div className="text-[#ccc] bg-[#191c23] my-5">
        <div className="sbui-card-content">
          {user?.is_verify === 2 && (
            <p className="text-red">
              Xác minh KYC bị từ chối, vui lòng xác minh lại!
            </p>
          )}
          {user?.is_verify === 0 && (
            <p className="text-yellow">
              Cập nhật thông tin KYC để tiến hành giao dịch
            </p>
          )}
          <KycForm />
        </div>
      </div>
    </div>
  );
}
