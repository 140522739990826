import { endpointTrading } from "_common/constants/common";
import api from "states/drivers";

const PriceEndpoint = `${endpointTrading}/price`;

export const PriceApi = {
  getList: async ({ params }) =>
    await api
      .get(`${PriceEndpoint}/list`, params)
      .then((result) => result.data.data),
};
