import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

export default function HistoryData() {
  const {
    authStore: { user },
  } = useStores();
  const [active, setActive] = useState("bet");

  const { data, refetch } = useQuery(
    ["game-player", "list", active],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 10,
          type: "trade",
          user_id: user?.id,
          status: active !== "all" ? active : "",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, [active]);

  return (
    <>
      <div className="h-[44px] bg-[#191c23] text-[14px] text-[#ccc] flex">
        <span
          className={`w-[25%] leading-[44px] text-center relative ${
            active === "all" && "!text-[#c94848]"
          }`}
          onClick={() => setActive("all")}
        >
          Toàn bộ
          {active === "all" && (
            <span className="w-[40px] h-[3px] bg-[#ee0a24] absolute bottom-[0] left-[35%]" />
          )}
        </span>
        <span
          className={`w-[25%] leading-[44px] text-center relative ${
            active === "bet" && "!text-[#c94848]"
          }`}
          onClick={() => setActive("bet")}
        >
          Đang thực hiện
          {active === "bet" && (
            <span className="w-[40px] h-[3px] bg-[#ee0a24] absolute bottom-[0] left-[35%]" />
          )}
        </span>
        <span
          className={`w-[25%] leading-[44px] text-center relative ${
            active === "lose" && "!text-[#c94848]"
          }`}
          onClick={() => setActive("lose")}
        >
          Đã thất bại
          {active === "lose" && (
            <span className="w-[40px] h-[3px] bg-[#ee0a24] absolute bottom-[0] left-[35%]" />
          )}
        </span>
        <span
          className={`w-[25%] leading-[44px] text-center relative ${
            active === "win" && "!text-[#c94848]"
          }`}
          onClick={() => setActive("win")}
        >
          Đã hoàn thành
          {active === 4 && (
            <span className="w-[40px] h-[3px] bg-[#ee0a24] absolute bottom-[0] left-[35%]" />
          )}
        </span>
      </div>
      <div className=" px-2 mx-auto mt-5 w-full">
        <div className="list-history-data">
          {get(data, "data", [])?.map((item) => (
            <div className="item" key={item?.id}>
              <h5 className="text-md text-center text-blue-600">
                {i18next.t("time_to_sell")}:{" "}
                {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}{" "}
              </h5>
              <div className="flex  justify-between items-center gap-2 mt-2">
                <div className="flex flex-col  w-full">
                  <h4 className="text-xl font-bold">{item?.game?.name}</h4>
                  <p
                    className={classNames("btn-bet-game mb-2 mt-2", {
                      buy: item?.bet_game === "buy",
                      sell: item?.bet_game === "sell",
                    })}
                  >
                    {i18next.t(item?.bet_game)}
                  </p>
                </div>
                <div className="flex flex-col items-end w-full">
                  <h4 className="text-xl font-bold">
                    {formatNumber(item?.amount)}
                    <small className="text-sm">USDT</small>
                  </h4>
                  <p className="text-sm font-bold">
                    {i18next.t("saleprice")} : {formatNumber(item?.open)}
                  </p>

                  {item?.status === "bet" && (
                    <>
                      <p className="text-sm font-bold">
                        {i18next.t("Số lượng đã bán")} :{" "}
                        {formatNumber(item?.amount_payback)} USDT
                      </p>
                      <p className="text-sm font-bold">
                        {i18next.t("Chưa hoàn tất")} :{" "}
                        {formatNumber(item?.amount_pending)} USDT
                      </p>
                    </>
                  )}
                  {item?.status === "win" && (
                    <p className="text-sm font-bold">
                      {i18next.t("Số tiền nhận")} :{" "}
                      {formatNumber(
                        Number(item?.amount_payback) * Number(item?.open)
                      )}{" "}
                      VND
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
