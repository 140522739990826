import React, { useEffect } from "react";

import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";
import { ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { handleCopyLink } from "_common/utils/helper";
import { get } from "lodash";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const schema = yup
  .object({
    full_name: yup.string().required(i18next.t("verify_require")),
  })
  .required();

export default function UpdateUser() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = (values) => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  useEffect(() => {
    if (!user) return;
    setValue("email", user?.email);
    setValue("full_name", user?.full_name);
    setValue("first_name", user?.first_name);
    setValue("last_name", user?.last_name);
  }, [user]);

  return (
    <div className="container mt-10">
      <HeaderTop title={i18next.t("edit_username")} />
      <div className="sbui-card">
        <div className="sbui-card-content">
          <form onSubmit={handleSubmit(onSave)}>
            <div className="flex flex-col items-center justify-between gap-4 mb-5 md:gap-10 md:flex-row">
              <div className="flex flex-col w-full">
                <div>
                  <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("full_name")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          placeholder={i18next.t("full_name")}
                          type="text"
                          className="sbui-input sbui-input--large"
                          {...register("full_name")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 mb-5 md:gap-10 md:flex-row">
              <div className="flex flex-col w-full">
                <span className="sbui-btn-container sbui-btn--w-full">
                  <button
                    type="submit"
                    className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                  >
                    <span>{i18next.t("send")}</span>
                  </button>
                </span>
              </div>
              <div className="flex items-center justify-between w-full" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
