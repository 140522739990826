import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const GamePlayerEndpoint = `${endpoint}/game-player`;

export const GamePlayerApi = {
  getList: async ({ params }) =>
    await api
      .get(`${GamePlayerEndpoint}/histories`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${GamePlayerEndpoint}/detail/${id}`)
      .then((result) => result.data),
  getInfo: async ({ params }) =>
    await api
      .get(`${GamePlayerEndpoint}/get-info`, params)
      .then((result) => result.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${GamePlayerEndpoint}/create`, params)
      .then((result) => result.data),
  update: async ({ id, params }) =>
    await api
      .put(`${GamePlayerEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${GamePlayerEndpoint}/delete/${id}`)
      .then((result) => result.data),
};
