import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import ListData from "./ListData";

const ReportPage = observer(() => {
  const {
    authStore: { user, user_wallet, display_balance, clear },
  } = useStores();

  return (
    <div className="container  px-4 mx-auto my-10">
      <HeaderTop title={i18next.t("report")} />
      <div className="flex flex-col w-full gap-4 mt-6">
        <div className="sbui-card w-full">
          <div className="sbui-card-content">
            <div className="flex items-center justify-between gap-2">
              <div className="flex flex-col items-center justify-center flex-1 gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="sbui-icon "
                >
                  <line x1="12" y1="1" x2="12" y2="23" />
                  <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
                </svg>
                <h3 className="text-gray-400 dark:text-gray-600">
                  {i18next.t("current_balance")}
                </h3>
                <span className="text-xl font-bold text-green-500 md:text-2xl">
                  {formatBalance(user_wallet?.amount, display_balance)}
                </span>
                <span className="-mt-3">(USDT)</span>
              </div>
              <div className="flex flex-col flex-1 gap-2">
                <div className="flex flex-col items-center justify-center">
                  <h4 className="text-gray-400 dark:text-gray-600">
                    {" "}
                    {i18next.t("buy")}
                  </h4>
                  <span className="font-bold text-red-500 md:text-2xl">
                    {formatNumber(user?.buy_total)} USDT
                  </span>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <h4 className="text-gray-400 dark:text-gray-600">
                    {" "}
                    {i18next.t("sell")}
                  </h4>
                  <span className="font-bold md:text-2xl text-lime-500">
                    {formatNumber(user?.sell_total)} USDT
                  </span>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <h4 className="text-gray-400 dark:text-gray-600">
                    {i18next.t("total_trade")}
                  </h4>
                  <span className="font-bold text-orange-400 md:text-2xl">
                    {formatNumber(user?.bet_total)} USDT
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sbui-card w-full">
          <div className="sbui-card-content">
            <div className="flex flex-col items-center justify-between h-full gap-4 md:flex-row">
              <div className="flex flex-row justify-between w-full gap-2 md:flex-col">
                <div className="flex flex-col items-center flex-1 gap-2 md:items-start">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sbui-icon "
                    >
                      <circle cx="12" cy="8" r="7" />
                      <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
                    </svg>
                    <h3 className="text-gray-400 dark:text-gray-600">
                      {i18next.t("total_revenue")}
                    </h3>
                  </div>
                  <span className="text-sm font-bold text-green-500 md:text-2xl">
                    {formatNumber(user?.win_total)} USDT
                  </span>
                </div>
                <div className="flex flex-col items-center flex-1 gap-2 md:items-start">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sbui-icon "
                    >
                      <line x1="12" y1="5" x2="12" y2="19" />
                      <polyline points="19 12 12 19 5 12" />
                    </svg>
                    <h3 className="text-gray-400 dark:text-gray-600">
                      {" "}
                      {i18next.t("deposit")}
                    </h3>
                  </div>
                  <span className="text-sm font-bold text-green-500 md:text-2xl">
                    {formatNumber(user?.bill_deposit)} USDT
                  </span>
                </div>
              </div>
              <div className="flex flex-row justify-between w-full gap-2 md:flex-col">
                <div className="flex flex-col items-center flex-1 gap-2 md:items-start">
                  <div className="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sbui-icon "
                    >
                      <rect x="1" y="4" width="22" height="16" rx="2" ry="2" />
                      <line x1="1" y1="10" x2="23" y2="10" />
                    </svg>
                    <h3 className="text-gray-400 dark:text-gray-600">
                      {i18next.t("net_profit")}
                    </h3>
                  </div>
                  <span className="text-sm font-bold md:text-2xl">
                    {formatNumber(user?.profit_total)} USDT
                  </span>
                </div>
                <div className="flex flex-col items-center flex-1 gap-2 md:items-start">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="sbui-icon "
                    >
                      <line x1="12" y1="19" x2="12" y2="5" />
                      <polyline points="5 12 12 5 19 12" />
                    </svg>
                    <h3 className="text-gray-400 dark:text-gray-600">
                      {i18next.t("withdraw")}
                    </h3>
                  </div>
                  <span className="text-sm font-bold text-red-500 md:text-2xl">
                    {formatNumber(user?.bill_withdraw)} USDT
                  </span>
                </div>
              </div>
              <div className="flex flex-row items-end justify-between w-full gap-2 md:flex-col">
                <div className="flex flex-col items-center flex-1 md:items-end">
                  <h4 className="text-gray-400 dark:text-gray-600">
                    {i18next.t("win_rate")}
                  </h4>
                  <span className="text-sm font-bold md:text-2xl text-lime-500">
                    {formatNumber(user?.win_rate)} %
                  </span>
                </div>
                <div className="flex flex-col items-center flex-1 md:items-end">
                  <h4 className="text-gray-400 dark:text-gray-600">
                    {i18next.t("loss_rate")}
                  </h4>
                  <span className="text-sm font-bold text-red-500 md:text-2xl">
                    {formatNumber(user?.lose_rate)} %
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: 40 }}>
        <ListData />
      </div>
    </div>
  );
});

export default ReportPage;
