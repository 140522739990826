import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import AddBankForm from "../Bank/AddBankForm";
import WithdrawSubmit from "./WithdrawSubmit";

export default function Recharge() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, my_bank },
  } = useStores();

  if (!my_bank?.id) return <AddBankForm />;

  return <WithdrawSubmit />;
}
