import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Portal } from "react-portal";

const ModalBet = observer(({ open, onClose, data, handleSubmit }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet">
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <h5>{i18next.t("confirm_order")}</h5>
            <span className="btn-close" onClick={onClose}>
              x
            </span>
          </div>
          <div className="modal-body">
            <div className="info-table mb-2">
              <div className="item">
                <span>{i18next.t("first_name")}</span>
                <span>
                  <b className="text-uppercase">
                    {data?.game_id?.split("usdt")?.[0]}
                  </b>
                  /USDT
                </span>
              </div>
              <div className="item">
                <span>{i18next.t("direction")}</span>
                {data?.game_type === "sell" && (
                  <span className="text-red">{i18next.t("sell")} </span>
                )}
                {data.game_type === "buy" && (
                  <span className="text-green">{i18next.t("buy")} </span>
                )}
              </div>
              <div className="item">
                <span>{i18next.t("number_of_trading_lots")}</span>
                <span>{formatNumber(data?.amount, "0,0.[0000]")}</span>
              </div>
              <div className="item">
                <span>{i18next.t("current_price")}</span>
                <span>{formatNumber(dataGame?.lastPrice, "0,0.[0000]")}</span>
              </div>
            </div>
            <div className="item-row">
              <span className="text-black">
                {i18next.t("balance_available")}:{" "}
                {formatNumber(usdtBalance?.amount, "0,0.[0000]")}
              </span>
              <span className="text-black">
                {i18next.t("transaction_fee")}: {formatNumber(0)}%
              </span>
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmit()}
            >
              {i18next.t("order_comfirmation")}
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalBet;
