import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { FundApi } from "states/api/fund";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useNavigate } from "react-router-dom";

import icon23 from "assets/img/icon/icon23.png";
import icon24 from "assets/img/icon/icon24.png";
import icon25 from "assets/img/icon/icon25.png";

import ListData from "./ListData";
import ModalAddFund from "./ModalAddFund";

const FundPage = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();

  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState("fund");

  const { data, refetch } = useQuery(
    ["funds", "list"],
    () =>
      FundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleOpenModal = (value) => {
    setOpenModal(true);
    setType(value);
  };

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  return (
    <div className="container fund-page  px-4 mx-auto my-10">
      <HeaderTop title="Lock-up mining " />
      <h3 className="text-center  mt-8">{i18next.t("fund_page_msg")}</h3>
      <h5>{i18next.t("fund_page_msg_2")}</h5>
      <div className="flex flex-col w-full gap-10 mt-5">
        <div className="flex flex-1 w-full">
          <div className="sbui-card card-info">
            <div className="sbui-card-content">
              <div className="flex flex-col gap-2 text-gray-400">
                <p className="text-center text-blue">
                  <b>{i18next.t("product_highlights")}</b>
                </p>
                <div className="col1">
                  <p>
                    <b>{i18next.t("deposit_and_withdraw_any")}</b>
                    <span>{i18next.t("profit_cycle")}</span>
                  </p>
                  <p>
                    <b>{i18next.t("released_daily")}</b>
                    <span>{i18next.t("recurring_interest")}</span>
                  </p>
                </div>
                <div className="col1">
                  <p>
                    <img src={icon23} alt="" />
                    <span>{i18next.t("fund_page_msg_3")}</span>
                  </p>
                  <p>
                    <img src={icon24} alt="" />
                    <span>{i18next.t("fund_page_msg_4")}</span>
                  </p>
                  <p>
                    <img src={icon25} alt="" />
                    <span>{i18next.t("fund_page_msg_5")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 w-full">
          <div className="sbui-card card-info">
            <div className="sbui-card-content">
              <div className="flex flex-col gap-2 text-gray-400">
                <p className="text-center text-blue">
                  <b>{i18next.t("example")}</b>
                </p>
                <p>
                  <b>{i18next.t("calculate_income")}</b>
                </p>
                <div>
                  <p>{i18next.t("fund_page_msg_6")}</p>
                  <p>{i18next.t("fund_page_msg_7")}</p>
                  <p>{i18next.t("fund_page_msg_8")}</p>
                  <p>{i18next.t("fund_page_msg_9")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 w-full">
          <div className="sbui-card card-info">
            <div className="sbui-card-content">
              <div className="flex flex-col gap-2 text-gray-400">
                <p>
                  <b>{i18next.t("about_liquidation_damage")}</b>
                </p>
                <div>
                  <p>{i18next.t("fund_page_msg_10")}</p>
                  <p>
                    {i18next.t("example")}: {i18next.t("fund_page_msg_11")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fund-footer">
          <button
            type="button"
            className="btn btn-fund"
            onClick={() => navigate("/board/fund/list")}
          >
            {i18next.t("i_want_to_join")}
          </button>
        </div>
      </div>
    </div>
  );
});

export default FundPage;
