import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faTimes } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";

import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import classNames from "classnames";

const SetTime = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { setLine, line, language },
  } = useStores();

  return (
    <div className="container language-page  mt-10">
      <HeaderTop title={i18next.t("switch_line")} />
      <div className="list-lang">
        <div
          className={classNames("item", {
            active: line === "line1",
          })}
          onClick={() => setLine("line1")}
        >
          <span>{i18next.t("line")} 1[201ms]</span>
        </div>
        <div
          className={classNames("item", {
            active: line === "line2",
          })}
          onClick={() => setLine("line2")}
        >
          <span>{i18next.t("line")} 2[440ms]</span>
        </div>
      </div>
    </div>
  );
});

export default SetTime;
