import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import HistoryData from "./HistoryData";

export default function HistoryFeaturePage() {
  const [params, setParams] = useState({});
  return (
    <div className="container page-history  mt-10">
      <HeaderTop title={i18next.t("contract_position")} />
      <HistoryData />
    </div>
  );
}
