import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Portal } from "react-portal";
import { formatNumber } from "_common/utils/formatValue";
import { get } from "lodash";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";

const PopupHallSell = observer(({ detail, onClose }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [amount, setAmount] = useState();

  const total_convert = useMemo(
    () => Number(amount || 0) * Number(get(detail, "open", 0)),
    [detail, amount]
  );

  const {
    register,
    trigger,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isValid, isSubmitted },
  } = useForm({});

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "amount") {
        setAmount(value?.amount);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.sellCoin({
        id: detail?.id,
        params: {
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-sell", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
        reset();
        onClose();
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (!amount)
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    const min_amount = Number(get(detail, "min_amount", 0));
    const max_amount = Number(get(detail, "max_amount", 0));

    if (min_amount > 0 && amount < min_amount)
      return NotificationManager.error(
        i18next.t(
          `Vui lòng nhập số lượng lớn hơn ${formatNumber(
            min_amount,
            "0,0.00"
          )} USDT`
        )
      );
    if (max_amount > 0 && amount > max_amount)
      return NotificationManager.error(
        i18next.t(
          `Vui lòng nhập số lượng nhỏ hơn hơn ${formatNumber(
            max_amount,
            "0,0.00"
          )} USDT`
        )
      );
    onSubmit({
      ...values,
    });
    return null;
  };

  return (
    <Portal>
      <div className="custom-modal">
        <div className="custom-mask" />
        <div className="custom-modal-body">
          <form onSubmit={handleSubmit(onSave)}>
            <div className="custom-modal-header text-[16px] text-center pt-[20px]">
              Bán ra USDT
            </div>
            <div className="custom-modal-content w-[85%] py-[20px] mx-[auto] flex flex-col">
              <input
                type="number"
                autoComplete="current-password"
                inputMode="decimal"
                step={0.000001}
                placeholder="Vui lòng điền số lượng USDT"
                className="custom-input !w-full text-white !bg-[#353c4a] !py-[10px] !rounded-[0]"
                {...register("amount")}
              />
              <span>
                Số tiền nhận được: {formatNumber(total_convert, "0,0")} VND
              </span>
            </div>
            <div className="custom-modal-footer border-t-[0.5px] border-[#13171a] flex">
              <span
                className="w-[50%] h-[48px] leading-[48px] text-[#ccc] text-center border-r-[0.5px] border-[#13171a]"
                onClick={() => onClose()}
              >
                Hủy bỏ
              </span>
              <button
                type="submit"
                className="w-[50%] h-[48px] leading-[48px] text-[#13c465] text-center"
              >
                Xác nhận
              </button>
            </div>
          </form>
        </div>
      </div>
    </Portal>
  );
});

export default PopupHallSell;
