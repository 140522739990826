import React from "react";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import ForgotPasswordForm from "./ForgotPasswordForm";

function ForgotPasswordPage() {
  return (
    <>
      <HeaderTop title="Quên mật khẩu" />
      <div className="sbui-sidepanel-container">
        <div className="sbui-sidepanel--medium auth-page">
          <div className="sbui-sidepanel !bg-[#13171a]">
            <div className="sbui-space-col sbui-space-y-6 h-full">
              <div className="sbui-sidepanel-content auth-container !p-[0]">
                <div className="flex flex-col h-full pt-[70px]">
                  <div className="sbui-auth">
                    <div className="sbui-space-col sbui-space-y-8 sbui-auth-label">
                      <ForgotPasswordForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordPage;
