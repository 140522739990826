import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";

import PopupHallSell from "./Popup/PopupHallSell";

const listText = [
  " xg2r1k********** Bán ra 48791USDT 40 Phút Trước ",
  " tgzdpz**********ok.com Bán ra 4191USDT 17 Phút Trước ",
  " q8iww9********** Bán ra 37921USDT 16 Phút Trước ",
  " wre8nw**********om Bán ra 12246USDT 97 Phút Trước ",
  " 38n351********** Bán ra 1759USDT 40 Phút Trước ",
  " 7dikwg**********om Bán ra 20408USDT 42 Phút Trước ",
  " lh9rof**********com Bán ra 9366USDT 38 Phút Trước ",
  " t4h67m**********.com Bán ra 41397USDT 88 Phút Trước ",
  " 9irp7o**********om Bán ra 30381USDT 62 Phút Trước ",
  " bdlwex**********m Bán ra 33838USDT 43 Phút Trước ",
  " qkldlg**********.com Bán ra 32114USDT 58 Phút Trước ",
  " 2jdt7@********** Bán ra 46151USDT 77 Phút Trước ",
  " d23urf**********om.vn Bán ra 49528USDT 82 Phút Trước ",
  " 1cs8o6**********ail.com Bán ra 17489USDT 49 Phút Trước ",
  " ouh2x@********** Bán ra 4066USDT 77 Phút Trước ",
  " mhmz1@********** Bán ra 36528USDT 41 Phút Trước ",
  " vynh36**********.com.vn Bán ra 12185USDT 83 Phút Trước ",
  " mfhiw9********** Bán ra 47808USDT 24 Phút Trước ",
  " wbrv5n**********o.com Bán ra 43202USDT 73 Phút Trước ",
  " ibbvku**********il.com Bán ra 12086USDT 99 Phút Trước ",
  " ********** Bán ra 54212.00USDT 32 Ngày Trước ",
  " ********** Bán ra 65653.00USDT 45 Ngày Trước ",
  " ********** Bán ra 25656.00USDT 65 Ngày Trước ",
  " ********** Bán ra 9823.00USDT 78 Ngày Trước ",
  " 1234********** Bán ra 10000.00USDT 12 Ngày Trước ",
  " ********** Bán ra 87446.00USDT 12 Ngày Trước ",
  " ********** Bán ra 57871.00USDT 23 Ngày Trước ",
  " ********** Bán ra 542454.00USDT 78 Ngày Trước ",
  " ********** Bán ra 5656.00USDT 89 Ngày Trước ",
  " ********** Bán ra 35000.00USDT 32 Ngày Trước ",
];

const defaultHeight = 2400;

const Notice = observer(() => {
  const [tranY, setTranY] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTranY((evl) => {
        if (evl <= -2400) return 0;
        return evl - 60;
      });
    }, 3600);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="hallswipe van-swipe">
      <div
        className="van-swipe__track van-swipe__track--vertical"
        style={{
          height: defaultHeight,
          transitionDuration: "500ms",
          transform: `translateY(${tranY}px)`,
        }}
      >
        {listText?.map((item, index) => (
          <div
            key={`item-${index?.toString()}`}
            className="van-swipe-item"
            style={{ height: 60 }}
          >
            <div>{item}</div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Notice;
