import React, { useRef, useState } from "react";
import { endpoint } from "_common/constants/common";
import { get } from "lodash";
import api from "states/drivers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

function UploadFile({ onReceiveImages = (data) => {}, ...props }) {
  const ref = useRef();

  const [fileListState, setFileListState] = useState([]);

  const handleUploadFailed = (res) => {
    console.log("upload failed", res);

    const { file } = res;
    const data = [...fileListState];
    const index = data.findIndex((i) => i.uid === file.uid);
    data.splice(index, 1);

    setFileListState(data);
  };

  const handleSuccess = ({ url, id, uid, group_code, file }) => {
    const newImage = {
      isNew: true,
      group_code,
      id,
      uid,
      name: get(file, "name"),
      status: "done",
      url,
    };
    const dataImages = [...fileListState, newImage];

    setFileListState(dataImages);
    if (typeof onReceiveImages === "function") {
      onReceiveImages(dataImages);
    }
  };

  const onFileUpload = async (file) => {
    try {
      // Create an object of formData
      const formData = new FormData();

      const uid = new Date().getTime();

      // Update the formData object
      formData.append("file", file, file?.name);
      formData.append("folder", props?.folder);
      formData.append("type", props?.type);
      formData.append("room_id", props?.room_id);
      formData.append("user_id", props?.user_id);
      formData.append("group_code", props?.group_code);
      formData.append("app_id", "2");
      formData.append("secure_code", "remix");
      formData.append("uid", uid);

      // Send formData object
      const result = await api.post(
        `${endpoint}/cskh/chat/send-image`,
        formData
      );
      console.log(result);
      if (get(result, "data.code") !== 200)
        throw new Error(get(result, "data.msg"));
      handleSuccess({
        file: get(file, "file"),
        uid,
        url: get(result, "data.data.thumb"),
        id: get(result, "data.data.id"),
        group_code: get(result, "data.data.group_code"),
      });
    } catch (err) {
      console.error(err);
      handleUploadFailed({ file });
    }
  };

  const onFileChange = (event) => {
    onFileUpload(event.target.files[0]);
  };

  return (
    <div className="upload-container">
      <input type="file" ref={ref} onChange={onFileChange} accept />
      <button
        type="button"
        className="btn btn-link text-decoration-none emoji-btn"
        id="emoji-btn"
        onClick={() => ref.current?.click()}
      >
        <FontAwesomeIcon icon={faImage} />
      </button>
    </div>
  );
}

export default UploadFile;
