import React, { useEffect, useMemo, useState } from "react";

import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationManager } from "react-notifications";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BankApi } from "states/api/bank";
import { optionBank } from "_common/constants/bank";

import { Portal } from "react-portal";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { get } from "lodash";

const schema = yup
  .object({
    bank_owner: yup.string().required(i18next.t("verify_require")),
    bank_number: yup.string().required(i18next.t("verify_require")),
    bank_name: yup.string().required(i18next.t("verify_require")),
  })
  .required();

export default function BindAccount() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, my_bank },
  } = useStores();

  const [params] = useSearchParams();

  const { mutate, isLoading } = useMutation(
    (variables) => {
      if (my_bank?.id) {
        return BankApi.update({ id: my_bank?.id, params: variables });
      }
      return BankApi.create({ params: variables });
    },
    {
      onSuccess: (res) => {
        navigate(-1);
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = (values) => {
    if (isLoading) return;
    trigger();

    mutate({
      ...values,
      bank_code: values?.bank_name,
      user_id: user?.id,
    });
  };

  const isDisabled = useMemo(() => !my_bank?.id, [my_bank]);

  useEffect(() => {
    if (!my_bank?.id) return;
    setValue("bank_owner", get(my_bank, "bank_owner"));
    setValue("bank_phone", get(my_bank, "bank_phone"));
    setValue("bank_number", get(my_bank, "bank_number"));
    setValue("bank_name", get(my_bank, "bank_name"));
  }, [my_bank]);

  return (
    <>
      <HeaderTop title="Liên kết tài khoản ngân hàng" />
      <form
        className="home-page !bg-[#13171a] pt-[46px]"
        onSubmit={handleSubmit(onSave)}
      >
        <div className="w-[95%] text-[14px] text-[#ccc] bg-[#191c23] p-[10px] rounded-[10px] mt-[15px] mx-[auto]">
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%] text-[18px] font-bold">
              Họ tên mở tài khoản
            </span>
            <input
              readOnly={!isDisabled}
              type="text"
              placeholder="Vui lòng nhập họ tên mở tài khoản"
              className="custom-input custom-input2 !w-[75%] !text-[16px] text-[#bbb] font-bold !pl-[0]"
              {...register("bank_owner")}
            />
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%] text-[18px] font-bold">Số điện thoại</span>
            <input
              readOnly={!isDisabled}
              type="text"
              placeholder="Vui lòng nhập Số điện thoại"
              className="custom-input custom-input2 !w-[75%] !text-[16px] text-[#bbb] font-bold !pl-[0]"
              {...register("bank_phone")}
            />
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%] text-[18px] font-bold">
              Tài khoản ngân hàng
            </span>
            <input
              readOnly={!isDisabled}
              type="text"
              placeholder="Vui lòng nhập Tài khoản ngân hàng"
              className="custom-input custom-input2 !w-[75%] !text-[16px] text-[#bbb] font-bold !pl-[0]"
              {...register("bank_number")}
            />
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%] text-[18px] font-bold">Tên ngân hàng</span>
            <input
              readOnly={!isDisabled}
              type="text"
              placeholder="Vui lòng nhập Tên ngân hàng"
              className="custom-input custom-input2 !w-[75%] !text-[16px] text-[#bbb] font-bold !pl-[0]"
              {...register("bank_name")}
            />
          </div>
        </div>
        {isDisabled && (
          <button
            type="submit"
            className="button w-[85%] text-[18px] text-white text-center !bg-[#c94848] rounded-[25px] p-[10px] mx-[auto] my-[15px]"
          >
            Xác nhận giao nộp
          </button>
        )}
      </form>
    </>
  );
}
