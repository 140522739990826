import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import i18next from "i18next";

function ModalBlock({ open, onClose }) {
  const navigate = useNavigate();
  const {
    authStore: { general },
  } = useStores();

  return (
    <Portal>
      <div
        className="modal modal-bet-game"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="classic-bet-now">
            <div className="title">
              <h5>{i18next.t("account_temporarily_locked")}</h5>
            </div>
            <div className="content">
              <p>{i18next.t("account_temporarily_locked_msg")}</p>
            </div>
            <div className="submit">
              <button
                type="button"
                className="btn btn-submit"
                style={{ width: 200 }}
                onClick={() => {
                  window.open(general?.link_support);
                }}
              >
                {i18next.t("contact_customer_care")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalBlock;
