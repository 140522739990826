import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import MarketPrice from "./MarketPrice";

const GameBoard = observer(({ game_id, data_price }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, betGame } = useNotify();
  const {
    authStore: { user, display_balance, loading, setLoading, general },
  } = useStores();

  const [game_type, setGameType] = useState("sell");
  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = (betGame) => {
    setGameType(betGame);
    setOpenModal(true);
  };

  const handleClose = () => {
    setGameType();
    setOpenModal(false);
  };

  return (
    <div className="feature-board">
      <div className="tabs">
        <div className="tab active">{i18next.t("market_price")}</div>
        <div className="tab">{i18next.t("waiting_list")}</div>
      </div>
      <MarketPrice data_price={data_price} game_id={game_id} />
    </div>
  );
});

export default GameBoard;
