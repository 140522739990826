import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import io from "socket.io-client";
import { socketChatUrl } from "_common/constants/common";
import { useQueryClient } from "@tanstack/react-query";

const socketChat = io(socketChatUrl, {
  transports: ["websocket"],
});

export const ChatContext = createContext({});

export default function ChatProvider({ children, user }) {
  const queryClient = useQueryClient();
  const [current_room, setCurrentRoom] = useState();
  const [chatData, setChatData] = useState({});
  const [time_update, setTimeUpdate] = useState();
  const [notifyData, setChat] = useState({});

  const onConnectSocket = (e) => {
    // socketChat.emit("join_room", {
    //   room_id: "selly_admin",
    // });
  };

  const onJoinRoom = (data) => {
    socketChat.emit("join_room", {
      ...data,
    });
  };

  const onLeaveRoom = (data) => {
    socketChat.emit("leave_room", {
      ...data,
    });
  };

  const pushChat = (data) => {
    socketChat.emit("push_notify", {
      ...data,
    });
  };

  useEffect(() => {
    if (current_room) {
      setChatData({});
      onJoinRoom({ room_id: current_room });
    }
    return () => {
      if (current_room) {
        onLeaveRoom({ room_id: current_room });
      }
    };
  }, [current_room]);

  const onGetListChat = (data) => {
    setChatData(data);
    setTimeUpdate(new Date().getTime());
  };

  const onNewChat = (data) => {
    setChat(data);
  };

  const sendChat = useCallback((data) => {
    socketChat.emit("messageChat", { ...data });
  }, []);

  useEffect(() => {
    if (!user?.id) return null;
    setTimeout(() => {
      onJoinRoom({ room_id: user?.username });
      socketChat.on("open", onConnectSocket);
      socketChat.on("chats", onGetListChat);
      socketChat.on("new_notify", onNewChat);
    }, 1000);
    return () => {
      socketChat.close();
    };
  }, [user?.id]);

  const returnDefault = useMemo(
    () => ({
      chatData,
      notifyData,
      time_update,
      current_room,
      pushChat,
      setCurrentRoom,
      onLeaveRoom,
      onJoinRoom,
      sendChat,
    }),
    [chatData, time_update, sendChat, onJoinRoom]
  );

  return (
    <ChatContext.Provider value={returnDefault}>
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  return useContext(ChatContext);
}
