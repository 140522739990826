import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const GameEndpoint = `${endpoint}/games`;

export const GameApi = {
  getGames: async ({ params }) =>
    await api
      .get(`${GameEndpoint}/list-games`, params)
      .then((result) => result.data.data),
  getList: async ({ params }) =>
    await api
      .get(`${GameEndpoint}/histories`, params)
      .then((result) => result.data.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${GameEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  betGame: async ({ params }) =>
    await api
      .post(`${GameEndpoint}/bet-game`, params)
      .then((result) => result.data),
  sellCoin: async ({ id, params }) =>
    await api
      .post(`${GameEndpoint}/sell-coin/${id}`, params)
      .then((result) => result.data),
  checkResult: async ({ params }) =>
    await api
      .post(`${GameEndpoint}/check-result`, params)
      .then((result) => result.data),
};
