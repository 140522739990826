import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import ModalImage from "./ModalImage";
import UploadFile from "./UploadFile";

function ChatInput({ onSubmit, onChatSuccess, detail }) {
  const [msg, setMessage] = useState("");
  const [image_src, setImageSrc] = useState();
  const [openModal, setOpenModal] = useState();

  const handleOnPasteImage = (pasteEvent) => {
    const item = pasteEvent.clipboardData.items[0];

    if (item.type.indexOf("image") === 0) {
      const blob = item.getAsFile();
      setImageSrc(blob);
      setOpenModal(true);
    }
  };

  useEffect(() => {
    window.addEventListener("paste", handleOnPasteImage);
    return () => {
      window.removeEventListener("paste", handleOnPasteImage);
    };
  }, []);

  const handleSubmit = () => {
    onSubmit(msg);
    setMessage("");
  };

  const handleChangeInput = (event) => {
    setMessage(event?.target?.value);
  };

  const handleChangeKeyUp = (event) => {
    if (event.keyCode === 13 && event.shiftKey) {
      event.preventDefault();
    } else if (event.which === 13) {
      handleSubmit();
    }
  };

  const onReceiveImages = (images) => {
    onChatSuccess(images[0]);
    // onSubmit({
    //   image:
    // })
  };

  return (
    <div className="chat-input-section p-3 p-lg-4">
      <div className="chat-input-wrapper">
        <div className="col-auto">
          <div className="chat-input-links me-2">
            <div className="links-list-item">
              <UploadFile
                onReceiveImages={onReceiveImages}
                folder="chat"
                room_id={detail?.room_id}
                user_id={detail?.user_id}
                group_code={detail?.group_code}
                type={detail?.type}
              />
            </div>
          </div>
        </div>
        <div className="col">
          <textarea
            rows={2}
            value={msg}
            type="text"
            className="form-control chat-input bg-light border-light"
            id="chat-input"
            placeholder={i18next.t("enter_content")}
            autoComplete="off"
            onChange={handleChangeInput}
            onKeyUp={handleChangeKeyUp}
          />
        </div>
        <div className="col-auto">
          <div className="chat-input-links ms-2">
            <div className="links-list-item">
              <button
                type="submit"
                className="btn btn-success chat-send waves-effect waves-light"
                onClick={() => handleSubmit()}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalImage
          allow_submit
          files={image_src}
          onClose={() => setOpenModal(false)}
          onReceiveImages={onReceiveImages}
          folder="chat"
          room_id={detail?.room_id}
          user_id={detail?.user_id}
          type={detail?.type}
        />
      )}
    </div>
  );
}

export default ChatInput;
