import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import io from "socket.io-client";
import { socketUrl } from "_common/constants/common";
import { useQueryClient } from "@tanstack/react-query";

let sock = null;

export const NotifyContext = createContext({});

export default function NotifyProvider({ user, children }) {
  const queryClient = useQueryClient();

  const [dataGame, setDataGame] = useState({});

  const [room_id, setRoomId] = useState();
  const [isConnect, setIsConnect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notify, setNotify] = useState(null);
  const [newResult, setNewResult] = useState(null);

  const onGetDataGame = (data) => {
    setDataGame(data);
  };

  const onConnectSocket = (e) => {
    console.log(e);
    setIsLoading(false);
    setIsConnect(true);
  };

  const onJoinRoom = (room_id) => {
    sock.emit("join_room", {
      room_id,
    });
  };

  const onLeaveRoom = (room_id) => {
    sock.emit("leave_room", {
      room_id,
    });
  };

  const pushNotify = (data) => {
    sock.emit("push_notify", {
      data,
    });
  };

  const onBetGame = (data) => {
    setNewResult(data);
    queryClient.invalidateQueries(["get_profile"]);
  };

  const onGetNotify = (data) => {
    setNotify(data);
  };

  const betGame = useCallback((data) => {
    sock.emit("bet_game", { ...data });
  }, []);

  const updatePrice = useCallback((data) => {
    sock.emit("update_price", { ...data });
  }, []);

  useEffect(() => {
    if (!isConnect) return null;
    if (!user?.id) return null;
    onJoinRoom(user?.id);

    return () => {
      onLeaveRoom(user?.id);
    };
  }, [isConnect, user?.id]);

  useEffect(() => {
    sock = io(socketUrl, {
      transports: ["websocket"],
    });

    sock.on("open", onConnectSocket);
    sock.on("data_game", onGetDataGame);
    sock.on("bet-game", onBetGame);
    sock.on("push_notify", onGetNotify);
    return () => {
      sock.close();
    };
  }, []);

  const returnDefault = useMemo(
    () => ({
      isLoading,
      notify,
      dataGame: { ...dataGame },
      setDataGame,
      newResult,
      room_id,
      setRoomId,
      pushNotify,
      setIsLoading,
      setNotify,
      betGame,
      updatePrice,
      onJoinRoom,
      onLeaveRoom,
      sock,
    }),
    [betGame, pushNotify, notify, room_id, newResult, isLoading, dataGame]
  );

  return (
    <NotifyContext.Provider value={returnDefault}>
      {children}
    </NotifyContext.Provider>
  );
}

export function useNotify() {
  return useContext(NotifyContext);
}
