import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";

import { PriceApi } from "states/api/price";
import HistoryData from "./HistoryData";
import MoneyInput from "./MoneyInput";
import MoneySelect from "./MoneySelect";
import ModalBet from "./ModalBet";

const GameBoard = observer(({ game_id }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, betGame } = useNotify();
  const {
    authStore: { user, setCoinOption, loading, setLoading, general },
  } = useStores();

  const [game_type, setGameType] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = (betGame) => {
    setGameType(betGame);
    setOpenModal(true);
  };

  const handleClose = () => {
    setGameType();
    setOpenModal(false);
  };

  return (
    <div className="trade-footer">
      <div className="sbui-checkbox-container sbui-checkbox-container--medium">
        <input
          id="remember_me"
          name="remember_me"
          type="checkbox"
          className="sbui-checkbox"
          value="remember_me"
        />
        <div className="sbui-checkbox__label-container">
          <div
            className="sbui-checkbox__label-container__label"
            htmlFor="remember_me"
            onClick={() => setCoinOption(game_id)}
          >
            {i18next.t("cancel_option")}
          </div>
        </div>
      </div>
      <div className="right">
        <button
          type="button"
          disabled={loading}
          onClick={() => handleSubmit("buy")}
          className="btn-buy "
        >
          <span>{i18next.t("buy")}</span>
        </button>
        <button
          type="button"
          disabled={loading}
          onClick={() => handleSubmit("sell")}
          className="btn-sell"
        >
          <span>{i18next.t("sell")}</span>
        </button>
      </div>
      {openModal && (
        <ModalBet
          type={game_type}
          onClose={handleClose}
          game_id={game_id}
          open={openModal}
        />
      )}
    </div>
  );
});

export default GameBoard;
