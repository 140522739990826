import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faIdCard } from "@fortawesome/free-solid-svg-icons";

import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { BankApi } from "states/api/bank";
import { UserWalletApi } from "states/api/wallet";
import moment from "moment";

export default function WalletList() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { setBankSelect },
  } = useStores();

  const [params] = useSearchParams();

  const type = params?.get("type");

  const { data, refetch } = useQuery(
    ["user_wallet", "list"],
    () =>
      UserWalletApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container mt-10">
      <HeaderTop title={i18next.t("manage_payment_methods")} />

      {data?.data?.length > 0 && (
        <div className="list-bank-info px-2 ">
          {data?.data?.map((item) => (
            <div
              key={item?.id}
              className="item card-note mt-2"
              onClick={() => {
                if (type === "select") {
                  setBankSelect(item);
                  navigate(-1);
                }
              }}
            >
              <div className=" flex  item-center gap-4 w-full mb-1">
                <div className="text-gray-500">
                  <img
                    src={item?.image_url}
                    style={{
                      width: 100,
                    }}
                    alt=""
                  />
                </div>
                <div className="text-gray-600">
                  <p>
                    {i18next.t("currency")}:{" "}
                    <span className="text-uppercase">{item?.symbol}</span>
                  </p>
                  <p>
                    {i18next.t("address")}: {item?.address}
                  </p>
                  <p>
                    {i18next.t("date")}:{" "}
                    <span className="symbol">
                      {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <button
        type="button"
        className="btn btn-add-wallet"
        onClick={() => navigate("/board/member/add-wallet")}
      >
        {i18next.t("add_wallet_address")}
      </button>
    </div>
  );
}
