import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "_common/utils/formatValue";

function MobileHeader({
  title,
  position = "",
  isBalance = false,
  isBell = false,
  isBack = true,
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, game_wallet },
  } = useStores();

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <div className="bg_header ">
      {isBack && (
        <span className="btn_back" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </span>
      )}
      <h1
        className={classNames("bg_header_name", {
          [position]: true,
        })}
      >
        {title}
      </h1>
      <div className="header_R">
        {isBell && <span className="btn_bell " />}
        {isBalance && (
          <div className="icon_inforID" id="GameMenu">
            <div className="icon_inforMoney">
              <span>{formatNumber(game_wallet?.amount)}</span>
            </div>
          </div>
        )}
      </div>

      <div>
        <span id="signout" className="btn_inforOut" />
      </div>
    </div>
  );
}

export default MobileHeader;
