import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import ModalBet from "./ModalBet";

const buttons = [20, 50, 75, 100];
const levers = [5, 10, 15, 20, 100];

const typeBet = [
  {
    type: "price",
    text: i18next.t("market_price"),
  },
  {
    type: "price_pending",
    text: i18next.t("waiting_list"),
  },
];

export default function GameBoard({ game_id, data_price }) {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame } = useNotify();
  const {
    authStore: { user, display_balance, loading, setLoading, general },
  } = useStores();

  const [game_type, setGameType] = useState("buy");
  const [bet_type, setBetType] = useState(typeBet[0].type);
  const [amount, setAmount] = useState(0);
  const [last_price, setLastPrice] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [amount_type, setAmountType] = useState();

  const handleChangeInput = (event) => {
    setAmount(parseInt(event?.target?.value, 10));
  };

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  useEffect(() => {
    setLastPrice(Number(data_price?.lastPrice));
  }, [bet_type]);

  return (
    <div className="feature-board">
      <div className="group-action">
        <button
          type="button"
          className={classNames("btn btn-buy ", {
            active: game_type === "buy",
          })}
          onClick={() => setGameType("buy")}
        >
          {i18next.t("buy_2")}
        </button>
        <button
          type="button"
          className={classNames("btn btn-sell ", {
            active: game_type === "sell",
          })}
          onClick={() => setGameType("sell")}
        >
          {i18next.t("sell_2")}
        </button>
      </div>

      <div className="type-select">
        <select onClick={(e) => setBetType(e.target?.value)}>
          {typeBet.map((item) => (
            <option key={`item-${item?.type.toString()}`} value={item.type}>
              {item.text}
            </option>
          ))}
        </select>
      </div>
      <div className="diver" />
      {bet_type === "price" && (
        <div
          className="qty-input"
          style={{
            width: "100%",
          }}
        >
          <input
            className="sbui-input sbui-input--large"
            readOnly
            value={i18next.t("optimal_price")}
            style={{
              width: "100%",
            }}
          />
        </div>
      )}
      {bet_type === "price_pending" && (
        <div
          className="qty-input"
          style={{
            width: "100%",
          }}
        >
          <button
            type="button"
            className="btn btn-add"
            onClick={() => setLastPrice(last_price > 1000 && last_price - 1000)}
          >
            -
          </button>
          <input
            placeholder="0.0"
            type="number"
            inputMode="decimal"
            className="sbui-input sbui-input--large"
            min={0}
            step="1"
            value={last_price}
            onChange={(e) => setLastPrice(e?.target?.value)}
          />
          <button
            type="button"
            className="btn btn-add"
            onClick={() => setLastPrice(last_price + 1000)}
          >
            +
          </button>
        </div>
      )}
      <div className="diver" />
      <div
        className="qty-input"
        style={{
          width: "100%",
        }}
      >
        <input
          placeholder={i18next.t("transaction_amount")}
          type="number"
          inputMode="decimal"
          className="sbui-input sbui-input--large"
          min={0}
          step="1"
          value={amount}
          onChange={handleChangeInput}
        />
        <button
          type="button"
          className="btn btn-add"
          style={{
            width: 64,
          }}
        >
          USDT
        </button>
      </div>
      <div className="info mt-2">
        <span>{i18next.t("available")}</span>
        <span>{formatNumber(0, "0,0.[00000]")}</span>
      </div>
      <div className="quantity-type">
        {buttons.map((item) => (
          <button
            key={`num-${item.toString()}`}
            type="button"
            className={classNames("btn", { active: amount_type >= item })}
            onClick={() => {
              const balance = usdtBalance?.amount;

              setAmountType(item);
              setAmount((balance * item) / 100);
            }}
          >
            {item}%
          </button>
        ))}
      </div>
      <div className="diver" />
      <div className="button-footer ">
        {game_type === "buy" && (
          <button
            type="button"
            className="btn btn-buy"
            onClick={() => setOpenModal(true)}
          >
            {i18next.t("buy_2")} BTC
          </button>
        )}

        {game_type === "sell" && (
          <button
            type="button"
            className="btn btn-sell"
            onClick={() => setOpenModal(true)}
          >
            {i18next.t("sell_2")} BTC
          </button>
        )}
      </div>

      {openModal && (
        <ModalBet
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={{
            game_id,
            game_type,
            amount,
          }}
        />
      )}
    </div>
  );
}
