import React from "react";
import { useStores } from "_common/hooks";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import NotifyProvider from "_common/component/NotifyProvider";

const UnAuthBasicLayout = observer(() => {
  const { authStore } = useStores();

  if (!authStore.logo) return <PageLoading />;

  return (
    <div className="mobile-layout">
      <div className="mask" />
      <div className="mobile-content">
        <NotifyProvider user={authStore.user}>
          <Outlet />
        </NotifyProvider>
      </div>
    </div>
  );
});

export default UnAuthBasicLayout;
