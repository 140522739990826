import classNames from "classnames";
import i18next from "i18next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useStores } from "_common/hooks";
import RegisterForm from "./RegisterForm";

function RegisterPage({ setTypeOpen }) {
  const [type, setType] = useState("email");
  const {
    authStore: { logo_login, general },
  } = useStores();

  return (
    <div className="sbui-sidepanel-container register-page">
      <div className="sbui-sidepanel--medium auth-page">
        <div className="sbui-sidepanel">
          <div className="sbui-space-col sbui-space-y-6 h-full">
            <div className="sbui-sidepanel-content auth-container !p-[0]">
              <div className="flex flex-col h-full">
                <div className="sbui-space-col sbui-space-y-8 sbui-auth-label">
                  <div className="p-[5px] text-[16px] text-[#b5b5b5] flex justify-between">
                    <Link to={general?.download_link}>Tải APP</Link>
                    {/* <Link to="/language">Ngôn ngữ</Link> */}
                  </div>
                  <img
                    src={logo_login}
                    alt=""
                    className="!w-[250px] !my-[50px] !mx-[auto]"
                  />
                  <RegisterForm type={type} setTypeOpen={setTypeOpen} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
