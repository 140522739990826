import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";

const buyPrice = [
  {
    price: 30639.424,
    volume: 0.176534,
  },
  {
    price: 30739.43,
    volume: 0.276534,
  },
  {
    price: 30839.425,
    volume: 0.0764,
  },
  {
    price: 30939.454,
    volume: 0.0534,
  },
  {
    price: 30439.787,
    volume: 0.0734,
  },
  {
    price: 30239.121,
    volume: 0.066534,
  },
  {
    price: 30439.45,
    volume: 0.056534,
  },
  {
    price: 30639.35,
    volume: 0.0334,
  },
  {
    price: 30539.885,
    volume: 0.0634,
  },
  {
    price: 30659.545,
    volume: 0.096534,
  },
];

const tabs = [
  {
    key: "1",
    text: i18next.t("hold_position"),
  },
  {
    key: "2",
    text: i18next.t("deal_done"),
  },
  {
    key: "3",
    text: i18next.t("closed"),
  },
  {
    key: "4",
    text: i18next.t("order_canceled"),
  },
  {
    key: "5",
    text: i18next.t("locked"),
  },
];

export default function HistoryTrade({ game_id }) {
  const [active, setActive] = useState(tabs[0].key);
  return (
    <div className="trade-history">
      <div className="tabs">
        {tabs.map((item) => (
          <div
            key={`item-${item.key.toString()}`}
            className={classNames("tab", { active: active === item.key })}
            onClick={() => setActive(item.key)}
          >
            {item.text}
          </div>
        ))}
      </div>
      <table className="table-data">
        <thead>
          <th>
            <td>{i18next.t("product")}</td>
            <td className="text-right">
              {i18next.t("successful_transaction_price")}
            </td>
            <td>{i18next.t("direction")}</td>
            <td>{i18next.t("number_of_trading_lots")}</td>
            <td>{i18next.t("command_details")}</td>
          </th>
        </thead>
        <tbody />
      </table>
    </div>
  );
}
