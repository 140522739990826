import React from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const MyTeamPage = observer(() => (
  <>
    <HeaderTop title="Danh sách nhóm" />
    <div className="home-page !bg-[#13171a] pt-[46px]">
      <span className="text-[14px] text-white m-[20px] inline-block">
        Không có số liệu
      </span>
    </div>
  </>
));

export default MyTeamPage;
