import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import i18next from "i18next";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import Pagination from "_common/component/Pagination";
import { formatNumber } from "_common/utils/formatValue";

export default function ListData() {
  const [params, setParams] = useState();
  const { data, refetch } = useQuery(
    ["game-player", "list"],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="my-10">
      <div className="flex items-center justify-between">
        <h2 className="text-xl"> {i18next.t("trade_history")}</h2>
      </div>
      <div className="sbui-loading">
        <div className="sbui-loading-content">
          <div className="flex flex-col mt-2">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full px-4 py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden rounded-lg shadow">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-gray-50 dark:bg-gray-800">
                      <tr>
                        <th
                          scope="col"
                          className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
                        >
                          {i18next.t("time")}
                        </th>
                        <th
                          scope="col"
                          className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
                        >
                          <div className="flex flex-col">
                            <span>{i18next.t("order_trade")}</span>
                            <span>{i18next.t("currencies")}</span>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
                        >
                          <div className="flex flex-col items-center justify-center">
                            <span>{i18next.t("timer_cycle")}</span>
                            <span>{i18next.t("money_amount")}</span>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="p-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:px-6 md:py-3"
                        >
                          <div className="flex flex-col items-center justify-center">
                            <span>{i18next.t("profit")}</span>
                            <span>{i18next.t("balance")}</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700 md:text-base">
                      {data?.data?.map((item) => (
                        <tr
                          key={item.id}
                          className="odd:bg-white dark:odd:bg-gray-800 even:bg-slate-50 dark:even:bg-gray-700"
                        >
                          <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
                            <span className=" text-orange-300 dark:opacity-50 break-words">
                              {moment(item?.created_at).format(
                                "HH:mm:ss DD/MM/YYYY"
                              )}
                            </span>
                          </td>
                          <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
                            <span className="text-green-600">
                              <div className="flex flex-col">
                                <span>{i18next.t(item?.bet_game)}</span>
                                <span className="dark:text-white">
                                  {item?.game?.name}
                                </span>
                              </div>
                            </span>
                          </td>
                          <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
                            <div className="flex flex-col items-center justify-center">
                              <span className="dark:text-white">
                                {item?.type}s
                              </span>
                              <span className="text-red-600">
                                {" "}
                                {formatNumber(item?.amount)}
                              </span>
                            </div>
                          </td>
                          <td className="p-2 text-gray-700 md:px-6 md:py-4 dark:text-gray-300">
                            <div className="flex flex-col justify-center text-center">
                              {item?.status === "bet" ? (
                                <span className="text-yellow-700">
                                  {i18next.t(item?.status)}
                                </span>
                              ) : (
                                <>
                                  <span
                                    className={classNames({
                                      "dark:text-white": !item?.amount_payback,
                                      "text-green-600":
                                        Number(item?.amount_payback) > 0,
                                    })}
                                  >
                                    {Number(item?.amount_payback) > 0 && "+"}
                                    {formatNumber(item?.amount_payback)}
                                  </span>
                                  <span className="dark:text-white">
                                    {formatNumber(item?.old_balance)}
                                  </span>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <Pagination /> */}
        </div>
      </div>
    </div>
  );
}
