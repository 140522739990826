import React, { useEffect, useMemo, useState } from "react";
import { Portal } from "react-portal";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { gameBetItemType } from "_common/constants/statusType";
import i18next from "i18next";

function ModalCountTime({ data, open, onClose }) {
  const navigate = useNavigate();
  const {
    authStore: { general },
  } = useStores();

  return (
    <Portal>
      <div
        className="modal modal-bet-game"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="classic-bet-now">
            <div className="title">
              <h5 className="count-time">{formatNumber(data?.time)}s</h5>
            </div>
            <div className="content">
              <div className="table-info">
                <div className="item">
                  <span>{i18next.t("order_price")}</span>
                  <span>{formatNumber(data?.price)}</span>
                </div>
                <div className="item">
                  <span>{i18next.t("direction")}</span>
                  <span>{i18next.t(gameBetItemType[data?.bet_game])}</span>
                </div>
                <div className="item">
                  <span>{i18next.t("purchase_quantity")}</span>
                  <span>{formatNumber(data?.amount, "0,0.[00000]")} USDT</span>
                </div>
                <div className="item">
                  <span>{i18next.t("current_price")}</span>
                  <span>{formatNumber(data?.lastPrice, "0,0.[00000]")}</span>
                </div>
                <div className="item">
                  <span>{i18next.t("estimated_earnings")}</span>
                  <span>{formatNumber(data?.profit, "0,0.[00000]")}</span>
                </div>
              </div>
            </div>
            <div className="submit">
              <button
                type="button"
                className="btn btn-submit"
                style={{ width: 200 }}
                onClick={onClose}
              >
                {i18next.t("close_2")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalCountTime;
