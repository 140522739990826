import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import i18next from "i18next";
import PageLoading from "_common/component/PageLoading";
import "pages/Chat/_styles/scss/style.scss";
import MobileHeader from "_common/component/Layout/Header/MobileHeader";

import MessageBoard from "./MessageBoard";

const ChatPage = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <MobileHeader title={i18next.t("online_support")} />
      <div className="support-page">
        <div className="page-content page-content-support">
          <div className="chat-wrapper d-lg-flex gap-1">
            {!isLoading ? <MessageBoard /> : <PageLoading />}
          </div>
        </div>
      </div>
    </>
  );
});

export default ChatPage;
